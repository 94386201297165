import ISO6391 from "iso-639-1";
import { customAlphabet } from "nanoid";
import * as unpacker from "unpacker";
import { unpack } from "unpacker";
import CryptoJS from "crypto-js";
import { load } from "cheerio";
import FormData from "form-data";
import cookie from "cookie";
import setCookieParser from "set-cookie-parser";
import { parse, stringify } from "hls-parser";
class NotFoundError extends Error {
  constructor(reason) {
    super(`Couldn't find a stream: ${reason ?? "not found"}`);
    this.name = "NotFoundError";
  }
}
function formatSourceMeta(v) {
  const types = [];
  if (v.scrapeMovie) types.push("movie");
  if (v.scrapeShow) types.push("show");
  return {
    type: "source",
    id: v.id,
    rank: v.rank,
    name: v.name,
    mediaTypes: types
  };
}
function formatEmbedMeta(v) {
  return {
    type: "embed",
    id: v.id,
    rank: v.rank,
    name: v.name
  };
}
function getAllSourceMetaSorted(list) {
  return list.sources.sort((a, b) => b.rank - a.rank).map(formatSourceMeta);
}
function getAllEmbedMetaSorted(list) {
  return list.embeds.sort((a, b) => b.rank - a.rank).map(formatEmbedMeta);
}
function getSpecificId(list, id) {
  const foundSource = list.sources.find((v) => v.id === id);
  if (foundSource) {
    return formatSourceMeta(foundSource);
  }
  const foundEmbed = list.embeds.find((v) => v.id === id);
  if (foundEmbed) {
    return formatEmbedMeta(foundEmbed);
  }
  return null;
}
function makeFullUrl(url, ops) {
  let leftSide = (ops == null ? void 0 : ops.baseUrl) ?? "";
  let rightSide = url;
  if (leftSide.length > 0 && !leftSide.endsWith("/")) leftSide += "/";
  if (rightSide.startsWith("/")) rightSide = rightSide.slice(1);
  const fullUrl = leftSide + rightSide;
  if (!fullUrl.startsWith("http://") && !fullUrl.startsWith("https://") && !fullUrl.startsWith("data:"))
    throw new Error(`Invald URL -- URL doesn't start with a http scheme: '${fullUrl}'`);
  const parsedUrl = new URL(fullUrl);
  Object.entries((ops == null ? void 0 : ops.query) ?? {}).forEach(([k, v]) => {
    parsedUrl.searchParams.set(k, v);
  });
  return parsedUrl.toString();
}
function makeFetcher(fetcher) {
  const newFetcher = (url, ops) => {
    return fetcher(url, {
      headers: (ops == null ? void 0 : ops.headers) ?? {},
      method: (ops == null ? void 0 : ops.method) ?? "GET",
      query: (ops == null ? void 0 : ops.query) ?? {},
      baseUrl: (ops == null ? void 0 : ops.baseUrl) ?? "",
      readHeaders: (ops == null ? void 0 : ops.readHeaders) ?? [],
      body: ops == null ? void 0 : ops.body,
      credentials: ops == null ? void 0 : ops.credentials
    });
  };
  const output = async (url, ops) => (await newFetcher(url, ops)).body;
  output.full = newFetcher;
  return output;
}
const flags = {
  // CORS are set to allow any origin
  CORS_ALLOWED: "cors-allowed",
  // the stream is locked on IP, so only works if
  // request maker is same as player (not compatible with proxies)
  IP_LOCKED: "ip-locked",
  // The source/embed is blocking cloudflare ip's
  // This flag is not compatible with a proxy hosted on cloudflare
  CF_BLOCKED: "cf-blocked",
  // Streams and sources with this flag wont be proxied
  // And will be exclusive to the extension
  PROXY_BLOCKED: "proxy-blocked"
};
const targets = {
  // browser with CORS restrictions
  BROWSER: "browser",
  // browser, but no CORS restrictions through a browser extension
  BROWSER_EXTENSION: "browser-extension",
  // native app, so no restrictions in what can be played
  NATIVE: "native",
  // any target, no target restrictions
  ANY: "any"
};
const targetToFeatures = {
  browser: {
    requires: [flags.CORS_ALLOWED],
    disallowed: []
  },
  "browser-extension": {
    requires: [],
    disallowed: []
  },
  native: {
    requires: [],
    disallowed: []
  },
  any: {
    requires: [],
    disallowed: []
  }
};
function getTargetFeatures(target, consistentIpForRequests, proxyStreams) {
  const features = targetToFeatures[target];
  if (!consistentIpForRequests) features.disallowed.push(flags.IP_LOCKED);
  if (proxyStreams) features.disallowed.push(flags.PROXY_BLOCKED);
  return features;
}
function flagsAllowedInFeatures(features, inputFlags) {
  const hasAllFlags = features.requires.every((v) => inputFlags.includes(v));
  if (!hasAllFlags) return false;
  const hasDisallowedFlag = features.disallowed.some((v) => inputFlags.includes(v));
  if (hasDisallowedFlag) return false;
  return true;
}
const captionTypes = {
  srt: "srt",
  vtt: "vtt"
};
function getCaptionTypeFromUrl(url) {
  const extensions = Object.keys(captionTypes);
  const type = extensions.find((v) => url.endsWith(`.${v}`));
  if (!type) return null;
  return type;
}
function labelToLanguageCode(label) {
  const code = ISO6391.getCode(label);
  if (code.length === 0) return null;
  return code;
}
function isValidLanguageCode(code) {
  if (!code) return false;
  return ISO6391.validate(code);
}
function removeDuplicatedLanguages(list) {
  const beenSeen = {};
  return list.filter((sub) => {
    if (beenSeen[sub.language]) return false;
    beenSeen[sub.language] = true;
    return true;
  });
}
async function addOpenSubtitlesCaptions(captions, ops, media) {
  try {
    const [imdbId, season, episode] = atob(media).split(".").map((x, i) => i === 0 ? x : Number(x) || null);
    if (!imdbId) return captions;
    const Res = await ops.proxiedFetcher(
      `https://rest.opensubtitles.org/search/${season && episode ? `episode-${episode}/` : ""}imdbid-${imdbId.slice(2)}${season && episode ? `/season-${season}` : ""}`,
      {
        headers: {
          "X-User-Agent": "VLSub 0.10.2"
        }
      }
    );
    const openSubtilesCaptions = [];
    for (const caption of Res) {
      const url = caption.SubDownloadLink.replace(".gz", "").replace("download/", "download/subencoding-utf8/");
      const language = labelToLanguageCode(caption.LanguageName);
      if (!url || !language) continue;
      else
        openSubtilesCaptions.push({
          id: url,
          opensubtitles: true,
          url,
          type: caption.SubFormat || "srt",
          hasCorsRestrictions: false,
          language
        });
    }
    return [...captions, ...removeDuplicatedLanguages(openSubtilesCaptions)];
  } catch {
    return captions;
  }
}
function requiresProxy(stream) {
  if (!stream.flags.includes(flags.CORS_ALLOWED) || !!(stream.headers && Object.keys(stream.headers).length > 0))
    return true;
  return false;
}
function setupProxy(stream) {
  const headers2 = stream.headers && Object.keys(stream.headers).length > 0 ? stream.headers : void 0;
  const options = {
    ...stream.type === "hls" && { depth: stream.proxyDepth ?? 0 }
  };
  const payload = {
    headers: headers2,
    options
  };
  if (stream.type === "hls") {
    payload.type = "hls";
    payload.url = stream.playlist;
    stream.playlist = `https://proxy.nsbx.ru/proxy?${new URLSearchParams({ payload: Buffer.from(JSON.stringify(payload)).toString("base64url") })}`;
  }
  if (stream.type === "file") {
    payload.type = "mp4";
    Object.entries(stream.qualities).forEach((entry) => {
      payload.url = entry[1].url;
      entry[1].url = `https://proxy.nsbx.ru/proxy?${new URLSearchParams({ payload: Buffer.from(JSON.stringify(payload)).toString("base64url") })}`;
    });
  }
  stream.headers = {};
  stream.flags = [flags.CORS_ALLOWED];
  return stream;
}
function makeSourcerer(state) {
  const mediaTypes = [];
  if (state.scrapeMovie) mediaTypes.push("movie");
  if (state.scrapeShow) mediaTypes.push("show");
  return {
    ...state,
    type: "source",
    disabled: state.disabled ?? false,
    mediaTypes
  };
}
function makeEmbed(state) {
  return {
    ...state,
    type: "embed",
    disabled: state.disabled ?? false,
    mediaTypes: void 0
  };
}
const providers$2 = [
  {
    id: "delta",
    rank: 699
  },
  {
    id: "alpha",
    rank: 695
  }
];
function embed$2(provider) {
  return makeEmbed({
    id: provider.id,
    name: provider.id.charAt(0).toUpperCase() + provider.id.slice(1),
    rank: provider.rank,
    disabled: false,
    async scrape(ctx) {
      const [query, baseUrl2] = ctx.url.split("|");
      const search = await ctx.fetcher.full("/search", {
        query: {
          query,
          provider: provider.id
        },
        credentials: "include",
        baseUrl: baseUrl2
      });
      if (search.statusCode === 429) throw new Error("Rate limited");
      if (search.statusCode !== 200) throw new NotFoundError("Failed to search");
      ctx.progress(50);
      const result = await ctx.fetcher("/provider", {
        query: {
          resourceId: search.body.url,
          provider: provider.id
        },
        credentials: "include",
        baseUrl: baseUrl2
      });
      ctx.progress(100);
      return result;
    }
  });
}
const [deltaScraper, alphaScraper] = providers$2.map(embed$2);
const vidLinkSources = async (ctx) => {
  var _a, _b, _c, _d;
  if (ctx.media.type === "movie") {
    const movieResult = await ctx.fetcher(`https://rdp.vidlink.pro/api/movie/${ctx.media.tmdbId}`);
    if (!movieResult) throw new NotFoundError("No movie found");
    return {
      embeds: [],
      stream: [
        {
          id: "primary",
          playlist: (_a = movieResult == null ? void 0 : movieResult.stream) == null ? void 0 : _a.playlist,
          type: "hls",
          proxyDepth: 2,
          flags: [flags.CORS_ALLOWED],
          captions: (_b = movieResult == null ? void 0 : movieResult.stream) == null ? void 0 : _b.captions
        }
      ]
    };
  } else if (ctx.media.type === "show") {
    const showResult = await ctx.fetcher(`https://rdp.vidlink.pro/api/tv/${ctx.media.tmdbId}/${ctx.media.season.number}/${ctx.media.episode.number}`);
    if (!showResult) throw new NotFoundError("No show found");
    return {
      embeds: [],
      stream: [
        {
          id: "primary",
          playlist: (_c = showResult == null ? void 0 : showResult.stream) == null ? void 0 : _c.playlist,
          type: "hls",
          proxyDepth: 2,
          flags: [flags.CORS_ALLOWED],
          captions: (_d = showResult == null ? void 0 : showResult.stream) == null ? void 0 : _d.captions
        }
      ]
    };
  }
  throw new Error("Invalid media type");
};
const vidLinkData = makeSourcerer({
  id: "vidlink",
  name: "Vidlink",
  rank: 932,
  flags: [flags.CORS_ALLOWED],
  scrapeMovie: vidLinkSources,
  scrapeShow: vidLinkSources
});
const warezcdnApiBase = "https://warezcdn.com/embed";
const warezcdnPlayerBase = "https://warezcdn.com/player";
const warezcdnWorkerProxy = "https://workerproxy.warezcdn.workers.dev";
function decrypt$1(input) {
  let output = atob(input);
  output = output.trim();
  output = output.split("").reverse().join("");
  let last = output.slice(-5);
  last = last.split("").reverse().join("");
  output = output.slice(0, -5);
  return `${output}${last}`;
}
async function getDecryptedId(ctx) {
  var _a;
  const page = await ctx.proxiedFetcher(`/player.php`, {
    baseUrl: warezcdnPlayerBase,
    headers: {
      Referer: `${warezcdnPlayerBase}/getEmbed.php?${new URLSearchParams({
        id: ctx.url,
        sv: "warezcdn"
      })}`
    },
    query: {
      id: ctx.url
    }
  });
  const allowanceKey = (_a = page.match(/let allowanceKey = "(.*?)";/)) == null ? void 0 : _a[1];
  if (!allowanceKey) throw new NotFoundError("Failed to get allowanceKey");
  const streamData = await ctx.proxiedFetcher("/functions.php", {
    baseUrl: warezcdnPlayerBase,
    method: "POST",
    body: new URLSearchParams({
      getVideo: ctx.url,
      key: allowanceKey
    })
  });
  const stream = JSON.parse(streamData);
  if (!stream.id) throw new NotFoundError("can't get stream id");
  const decryptedId = decrypt$1(stream.id);
  if (!decryptedId) throw new NotFoundError("can't get file id");
  return decryptedId;
}
const cdnListing = [50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64];
async function checkUrls(ctx, fileId) {
  for (const id of cdnListing) {
    const url = `https://cloclo${id}.cloud.mail.ru/weblink/view/${fileId}`;
    const response = await ctx.proxiedFetcher.full(url, {
      method: "GET",
      headers: {
        Range: "bytes=0-1"
      }
    });
    if (response.statusCode === 206) return url;
  }
  return null;
}
const warezcdnembedMp4Scraper = makeEmbed({
  id: "warezcdnembedmp4",
  // WarezCDN is both a source and an embed host
  name: "WarezCDN MP4",
  // method no longer works
  rank: 82,
  disabled: true,
  async scrape(ctx) {
    const decryptedId = await getDecryptedId(ctx);
    if (!decryptedId) throw new NotFoundError("can't get file id");
    const streamUrl = await checkUrls(ctx, decryptedId);
    if (!streamUrl) throw new NotFoundError("can't get stream id");
    return {
      stream: [
        {
          id: "primary",
          captions: [],
          qualities: {
            unknown: {
              type: "mp4",
              url: `${warezcdnWorkerProxy}/?${new URLSearchParams({
                url: streamUrl
              })}`
            }
          },
          type: "file",
          flags: [flags.CORS_ALLOWED]
        }
      ]
    };
  }
});
const baseUrl$2 = "https://api.whvx.net";
async function comboScraper(ctx) {
  var _a;
  const query = {
    title: ctx.media.title,
    releaseYear: ctx.media.releaseYear,
    tmdbId: ctx.media.tmdbId,
    imdbId: ctx.media.imdbId,
    type: ctx.media.type,
    ...ctx.media.type === "show" && {
      season: ctx.media.season.number.toString(),
      episode: ctx.media.episode.number.toString()
    }
  };
  const res = await ctx.fetcher("/status", { baseUrl: baseUrl$2 });
  if (((_a = res.providers) == null ? void 0 : _a.length) === 0) throw new NotFoundError("No providers available");
  const embeds = res.providers.map((provider) => {
    return {
      embedId: provider,
      url: JSON.stringify(query)
    };
  });
  return {
    embeds
  };
}
const whvxScraper = makeSourcerer({
  id: "whvx",
  name: "Sigma (may have 4k)",
  rank: 128,
  disabled: false,
  flags: [flags.CORS_ALLOWED],
  scrapeMovie: comboScraper,
  scrapeShow: comboScraper
});
const providers$1 = [
  {
    id: "nova",
    rank: 720
  },
  {
    id: "astra",
    rank: 710
  },
  {
    id: "orion",
    rank: 700,
    disabled: true
  }
];
const headers = {
  Origin: "https://www.vidbinge.com",
  Referer: "https://www.vidbinge.com"
};
function embed$1(provider) {
  return makeEmbed({
    id: provider.id,
    name: provider.id.charAt(0).toUpperCase() + provider.id.slice(1),
    rank: provider.rank,
    disabled: provider.disabled,
    async scrape(ctx) {
      let progress = 50;
      const interval = setInterval(() => {
        if (progress < 100) {
          progress += 1;
          ctx.progress(progress);
        }
      }, 100);
      try {
        const search = await ctx.fetcher.full(
          `${baseUrl$2}/search?query=${encodeURIComponent(ctx.url)}&provider=${provider.id}`,
          { headers }
        );
        if (search.statusCode === 429) {
          throw new Error("Rate limited");
        } else if (search.statusCode !== 200) {
          throw new NotFoundError("Failed to search");
        }
        const result = await ctx.fetcher(
          `${baseUrl$2}/source?resourceId=${encodeURIComponent(search.body.url)}&provider=${provider.id}`,
          { headers }
        );
        clearInterval(interval);
        ctx.progress(100);
        return result;
      } catch (error) {
        clearInterval(interval);
        ctx.progress(100);
        throw new NotFoundError("Failed to search");
      }
    }
  });
}
const [novaScraper, astraScraper, orionScraper] = providers$1.map(embed$1);
const SKIP_VALIDATION_CHECK_IDS = [
  warezcdnembedMp4Scraper.id,
  deltaScraper.id,
  alphaScraper.id,
  novaScraper.id,
  astraScraper.id,
  orionScraper.id,
  vidLinkData.id
];
function isValidStream$1(stream) {
  if (!stream) return false;
  if (stream.type === "hls") {
    if (!stream.playlist) return false;
    return true;
  }
  if (stream.type === "file") {
    const validQualities = Object.values(stream.qualities).filter((v) => v.url.length > 0);
    if (validQualities.length === 0) return false;
    return true;
  }
  return false;
}
async function validatePlayableStream(stream, ops, sourcererId) {
  if (SKIP_VALIDATION_CHECK_IDS.includes(sourcererId)) return stream;
  if (stream.type === "hls") {
    if (stream.playlist.startsWith("data:")) return stream;
    const result = await ops.proxiedFetcher.full(stream.playlist, {
      method: "GET",
      headers: {
        ...stream.preferredHeaders,
        ...stream.headers
      }
    });
    if (result.statusCode < 200 || result.statusCode >= 400) return null;
    return stream;
  }
  if (stream.type === "file") {
    const validQualitiesResults = await Promise.all(
      Object.values(stream.qualities).map(
        (quality) => ops.proxiedFetcher.full(quality.url, {
          method: "GET",
          headers: {
            ...stream.preferredHeaders,
            ...stream.headers,
            Range: "bytes=0-1"
          }
        })
      )
    );
    const validQualities = stream.qualities;
    Object.keys(stream.qualities).forEach((quality, index) => {
      if (validQualitiesResults[index].statusCode < 200 || validQualitiesResults[index].statusCode >= 400) {
        delete validQualities[quality];
      }
    });
    if (Object.keys(validQualities).length === 0) return null;
    return { ...stream, qualities: validQualities };
  }
  return null;
}
async function validatePlayableStreams(streams, ops, sourcererId) {
  if (SKIP_VALIDATION_CHECK_IDS.includes(sourcererId)) return streams;
  return (await Promise.all(streams.map((stream) => validatePlayableStream(stream, ops, sourcererId)))).filter(
    (v) => v !== null
  );
}
async function scrapeInvidualSource(list, ops) {
  const sourceScraper = list.sources.find((v) => ops.id === v.id);
  if (!sourceScraper) throw new Error("Source with ID not found");
  if (ops.media.type === "movie" && !sourceScraper.scrapeMovie) throw new Error("Source is not compatible with movies");
  if (ops.media.type === "show" && !sourceScraper.scrapeShow) throw new Error("Source is not compatible with shows");
  const contextBase = {
    fetcher: ops.fetcher,
    proxiedFetcher: ops.proxiedFetcher,
    progress(val) {
      var _a, _b;
      (_b = (_a = ops.events) == null ? void 0 : _a.update) == null ? void 0 : _b.call(_a, {
        id: sourceScraper.id,
        percentage: val,
        status: "pending"
      });
    }
  };
  let output = null;
  if (ops.media.type === "movie" && sourceScraper.scrapeMovie)
    output = await sourceScraper.scrapeMovie({
      ...contextBase,
      media: ops.media
    });
  else if (ops.media.type === "show" && sourceScraper.scrapeShow)
    output = await sourceScraper.scrapeShow({
      ...contextBase,
      media: ops.media
    });
  if (output == null ? void 0 : output.stream) {
    output.stream = output.stream.filter((stream) => isValidStream$1(stream)).filter((stream) => flagsAllowedInFeatures(ops.features, stream.flags));
    output.stream = output.stream.map(
      (stream) => requiresProxy(stream) && ops.proxyStreams ? setupProxy(stream) : stream
    );
  }
  if (!output) throw new Error("output is null");
  output.embeds = output.embeds.filter((embed2) => {
    const e = list.embeds.find((v) => v.id === embed2.embedId);
    if (!e || e.disabled) return false;
    return true;
  });
  if (!ops.disableOpensubtitles)
    for (const embed2 of output.embeds)
      embed2.url = `${embed2.url}${btoa("MEDIA=")}${btoa(
        `${ops.media.imdbId}${ops.media.type === "show" ? `.${ops.media.season.number}.${ops.media.episode.number}` : ""}`
      )}`;
  if ((!output.stream || output.stream.length === 0) && output.embeds.length === 0)
    throw new NotFoundError("No streams found");
  if (output.stream && output.stream.length > 0 && output.embeds.length === 0) {
    const playableStreams = await validatePlayableStreams(output.stream, ops, sourceScraper.id);
    if (playableStreams.length === 0) throw new NotFoundError("No playable streams found");
    if (!ops.disableOpensubtitles)
      for (const playableStream of playableStreams) {
        playableStream.captions = await addOpenSubtitlesCaptions(
          playableStream.captions,
          ops,
          btoa(
            `${ops.media.imdbId}${ops.media.type === "show" ? `.${ops.media.season.number}.${ops.media.episode.number}` : ""}`
          )
        );
      }
    output.stream = playableStreams;
  }
  return output;
}
async function scrapeIndividualEmbed(list, ops) {
  const embedScraper = list.embeds.find((v) => ops.id === v.id);
  if (!embedScraper) throw new Error("Embed with ID not found");
  let url = ops.url;
  let media;
  if (ops.url.includes(btoa("MEDIA="))) [url, media] = url.split(btoa("MEDIA="));
  const output = await embedScraper.scrape({
    fetcher: ops.fetcher,
    proxiedFetcher: ops.proxiedFetcher,
    url,
    progress(val) {
      var _a, _b;
      (_b = (_a = ops.events) == null ? void 0 : _a.update) == null ? void 0 : _b.call(_a, {
        id: embedScraper.id,
        percentage: val,
        status: "pending"
      });
    }
  });
  output.stream = output.stream.filter((stream) => isValidStream$1(stream)).filter((stream) => flagsAllowedInFeatures(ops.features, stream.flags));
  if (output.stream.length === 0) throw new NotFoundError("No streams found");
  output.stream = output.stream.map(
    (stream) => requiresProxy(stream) && ops.proxyStreams ? setupProxy(stream) : stream
  );
  const playableStreams = await validatePlayableStreams(output.stream, ops, embedScraper.id);
  if (playableStreams.length === 0) throw new NotFoundError("No playable streams found");
  if (media && !ops.disableOpensubtitles)
    for (const playableStream of playableStreams)
      playableStream.captions = await addOpenSubtitlesCaptions(playableStream.captions, ops, media);
  output.stream = playableStreams;
  return output;
}
function reorderOnIdList(order, list) {
  const copy = [...list];
  copy.sort((a, b) => {
    const aIndex = order.indexOf(a.id);
    const bIndex = order.indexOf(b.id);
    if (aIndex >= 0 && bIndex >= 0) return aIndex - bIndex;
    if (bIndex >= 0) return 1;
    if (aIndex >= 0) return -1;
    return b.rank - a.rank;
  });
  return copy;
}
async function runAllProviders(list, ops) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
  const sources = reorderOnIdList(ops.sourceOrder ?? [], list.sources).filter((source) => {
    if (ops.media.type === "movie") return !!source.scrapeMovie;
    if (ops.media.type === "show") return !!source.scrapeShow;
    return false;
  });
  const embeds = reorderOnIdList(ops.embedOrder ?? [], list.embeds);
  const embedIds = embeds.map((embed2) => embed2.id);
  let lastId = "";
  const contextBase = {
    fetcher: ops.fetcher,
    proxiedFetcher: ops.proxiedFetcher,
    progress(val) {
      var _a2, _b2;
      (_b2 = (_a2 = ops.events) == null ? void 0 : _a2.update) == null ? void 0 : _b2.call(_a2, {
        id: lastId,
        percentage: val,
        status: "pending"
      });
    }
  };
  (_b = (_a = ops.events) == null ? void 0 : _a.init) == null ? void 0 : _b.call(_a, {
    sourceIds: sources.map((v) => v.id)
  });
  for (const source of sources) {
    (_d = (_c = ops.events) == null ? void 0 : _c.start) == null ? void 0 : _d.call(_c, source.id);
    lastId = source.id;
    let output = null;
    try {
      if (ops.media.type === "movie" && source.scrapeMovie)
        output = await source.scrapeMovie({
          ...contextBase,
          media: ops.media
        });
      else if (ops.media.type === "show" && source.scrapeShow)
        output = await source.scrapeShow({
          ...contextBase,
          media: ops.media
        });
      if (output) {
        output.stream = (output.stream ?? []).filter(isValidStream$1).filter((stream) => flagsAllowedInFeatures(ops.features, stream.flags));
        output.stream = output.stream.map(
          (stream) => requiresProxy(stream) && ops.proxyStreams ? setupProxy(stream) : stream
        );
      }
      if (!output || !((_e = output.stream) == null ? void 0 : _e.length) && !output.embeds.length) {
        throw new NotFoundError("No streams found");
      }
    } catch (error) {
      const updateParams = {
        id: source.id,
        percentage: 100,
        status: error instanceof NotFoundError ? "notfound" : "failure",
        reason: error instanceof NotFoundError ? error.message : void 0,
        error: error instanceof NotFoundError ? void 0 : error
      };
      (_g = (_f = ops.events) == null ? void 0 : _f.update) == null ? void 0 : _g.call(_f, updateParams);
      continue;
    }
    if (!output) throw new Error("Invalid media type");
    if ((_h = output.stream) == null ? void 0 : _h[0]) {
      const playableStream = await validatePlayableStream(output.stream[0], ops, source.id);
      if (!playableStream) throw new NotFoundError("No streams found");
      if (!ops.disableOpensubtitles)
        playableStream.captions = await addOpenSubtitlesCaptions(
          playableStream.captions,
          ops,
          btoa(
            `${ops.media.imdbId}${ops.media.type === "show" ? `.${ops.media.season.number}.${ops.media.episode.number}` : ""}`
          )
        );
      return {
        sourceId: source.id,
        stream: playableStream
      };
    }
    const sortedEmbeds = output.embeds.filter((embed2) => {
      const e = list.embeds.find((v) => v.id === embed2.embedId);
      return e && !e.disabled;
    }).sort((a, b) => embedIds.indexOf(a.embedId) - embedIds.indexOf(b.embedId));
    if (sortedEmbeds.length > 0) {
      (_j = (_i = ops.events) == null ? void 0 : _i.discoverEmbeds) == null ? void 0 : _j.call(_i, {
        embeds: sortedEmbeds.map((embed2, i) => ({
          id: [source.id, i].join("-"),
          embedScraperId: embed2.embedId
        })),
        sourceId: source.id
      });
    }
    for (const [ind, embed2] of sortedEmbeds.entries()) {
      const scraper = embeds.find((v) => v.id === embed2.embedId);
      if (!scraper) throw new Error("Invalid embed returned");
      const id = [source.id, ind].join("-");
      (_l = (_k = ops.events) == null ? void 0 : _k.start) == null ? void 0 : _l.call(_k, id);
      lastId = id;
      let embedOutput;
      try {
        embedOutput = await scraper.scrape({
          ...contextBase,
          url: embed2.url
        });
        embedOutput.stream = embedOutput.stream.filter(isValidStream$1).filter((stream) => flagsAllowedInFeatures(ops.features, stream.flags));
        embedOutput.stream = embedOutput.stream.map(
          (stream) => requiresProxy(stream) && ops.proxyStreams ? setupProxy(stream) : stream
        );
        if (embedOutput.stream.length === 0) {
          throw new NotFoundError("No streams found");
        }
        const playableStream = await validatePlayableStream(embedOutput.stream[0], ops, embed2.embedId);
        if (!playableStream) throw new NotFoundError("No streams found");
        if (!ops.disableOpensubtitles)
          playableStream.captions = await addOpenSubtitlesCaptions(
            playableStream.captions,
            ops,
            btoa(
              `${ops.media.imdbId}${ops.media.type === "show" ? `.${ops.media.season.number}.${ops.media.episode.number}` : ""}`
            )
          );
        embedOutput.stream = [playableStream];
      } catch (error) {
        const updateParams = {
          id,
          percentage: 100,
          status: error instanceof NotFoundError ? "notfound" : "failure",
          reason: error instanceof NotFoundError ? error.message : void 0,
          error: error instanceof NotFoundError ? void 0 : error
        };
        (_n = (_m = ops.events) == null ? void 0 : _m.update) == null ? void 0 : _n.call(_m, updateParams);
        continue;
      }
      return {
        sourceId: source.id,
        embedId: scraper.id,
        stream: embedOutput.stream[0]
      };
    }
  }
  return null;
}
function makeControls(ops) {
  const list = {
    embeds: ops.embeds,
    sources: ops.sources
  };
  const providerRunnerOps = {
    features: ops.features,
    fetcher: makeFetcher(ops.fetcher),
    proxiedFetcher: makeFetcher(ops.proxiedFetcher ?? ops.fetcher),
    proxyStreams: ops.proxyStreams
  };
  return {
    runAll(runnerOps) {
      return runAllProviders(list, {
        ...providerRunnerOps,
        ...runnerOps
      });
    },
    runSourceScraper(runnerOps) {
      return scrapeInvidualSource(list, {
        ...providerRunnerOps,
        ...runnerOps
      });
    },
    runEmbedScraper(runnerOps) {
      return scrapeIndividualEmbed(list, {
        ...providerRunnerOps,
        ...runnerOps
      });
    },
    getMetadata(id) {
      return getSpecificId(list, id);
    },
    listSources() {
      return getAllSourceMetaSorted(list);
    },
    listEmbeds() {
      return getAllEmbedMetaSorted(list);
    }
  };
}
const nanoid = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", 10);
const baseUrl$1 = "https://d000d.com";
const doodScraper = makeEmbed({
  id: "dood",
  name: "dood",
  rank: 173,
  async scrape(ctx) {
    var _a, _b;
    let url = ctx.url;
    if (ctx.url.includes("primewire")) {
      const request = await ctx.proxiedFetcher.full(ctx.url);
      url = request.finalUrl;
    }
    const id = url.split("/d/")[1] || url.split("/e/")[1];
    const doodData = await ctx.proxiedFetcher(`/e/${id}`, {
      method: "GET",
      baseUrl: baseUrl$1
    });
    const dataForLater = (_a = doodData.match(/\?token=([^&]+)&expiry=/)) == null ? void 0 : _a[1];
    const path = (_b = doodData.match(/\$\.get\('\/pass_md5([^']+)/)) == null ? void 0 : _b[1];
    const thumbnailTrack = doodData.match(/thumbnails:\s\{\s*vtt:\s'([^']*)'/);
    const doodPage = await ctx.proxiedFetcher(`/pass_md5${path}`, {
      headers: {
        Referer: `${baseUrl$1}/e/${id}`
      },
      method: "GET",
      baseUrl: baseUrl$1
    });
    const downloadURL = `${doodPage}${nanoid()}?token=${dataForLater}&expiry=${Date.now()}`;
    if (!downloadURL.startsWith("http")) throw new Error("Invalid URL");
    return {
      stream: [
        {
          id: "primary",
          type: "file",
          flags: [],
          captions: [],
          qualities: {
            unknown: {
              type: "mp4",
              url: downloadURL
            }
          },
          headers: {
            Referer: baseUrl$1
          },
          ...thumbnailTrack ? {
            thumbnailTrack: {
              type: "vtt",
              url: `https:${thumbnailTrack[1]}`
            }
          } : {}
        }
      ]
    };
  }
});
const evalCodeRegex$3 = /eval\((.*)\)/g;
const fileRegex$2 = /file:"(.*?)"/g;
const tracksRegex$3 = /\{file:"([^"]+)",kind:"thumbnails"\}/g;
const droploadScraper = makeEmbed({
  id: "dropload",
  name: "Dropload",
  rank: 120,
  scrape: async (ctx) => {
    const mainPageRes = await ctx.proxiedFetcher.full(ctx.url, {
      headers: {
        referer: ctx.url
      }
    });
    const mainPageUrl = new URL(mainPageRes.finalUrl);
    const mainPage = mainPageRes.body;
    const evalCode = mainPage.match(evalCodeRegex$3);
    if (!evalCode) throw new Error("Failed to find eval code");
    const unpacked = unpack(evalCode[1]);
    const file = fileRegex$2.exec(unpacked);
    const thumbnailTrack = tracksRegex$3.exec(unpacked);
    if (!(file == null ? void 0 : file[1])) throw new Error("Failed to find file");
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          playlist: file[1],
          flags: [flags.IP_LOCKED, flags.CORS_ALLOWED],
          captions: [],
          ...thumbnailTrack ? {
            thumbnailTrack: {
              type: "vtt",
              url: mainPageUrl.origin + thumbnailTrack[1]
            }
          } : {}
        }
      ]
    };
  }
});
const febBoxBase = `https://www.febbox.com`;
function parseInputUrl(url) {
  const [type, id, seasonId, episodeId] = url.slice(1).split("/");
  const season = seasonId ? parseInt(seasonId, 10) : void 0;
  const episode = episodeId ? parseInt(episodeId, 10) : void 0;
  return {
    type,
    id,
    season,
    episode
  };
}
async function getFileList(ctx, shareKey, parentId) {
  var _a;
  const query = {
    share_key: shareKey,
    pwd: ""
  };
  if (parentId) {
    query.parent_id = parentId.toString();
    query.page = "1";
  }
  const streams = await ctx.proxiedFetcher("/file/file_share_list", {
    headers: {
      "accept-language": "en"
      // without this header, the request is marked as a webscraper
    },
    baseUrl: febBoxBase,
    query
  });
  return ((_a = streams.data) == null ? void 0 : _a.file_list) ?? [];
}
function isValidStream(file) {
  return file.ext === "mp4" || file.ext === "mkv";
}
async function getStreams(ctx, shareKey, type, season, episode) {
  const streams = await getFileList(ctx, shareKey);
  if (type === "show") {
    const seasonFolder = streams.find((v) => {
      if (!v.is_dir) return false;
      return v.file_name.toLowerCase() === `season ${season}`;
    });
    if (!seasonFolder) return [];
    const episodes = await getFileList(ctx, shareKey, seasonFolder.fid);
    const s = (season == null ? void 0 : season.toString()) ?? "0";
    const e = (episode == null ? void 0 : episode.toString()) ?? "0";
    const episodeRegex = new RegExp(`[Ss]0*${s}[Ee]0*${e}`);
    return episodes.filter((file) => {
      if (file.is_dir) return false;
      const match = file.file_name.match(episodeRegex);
      if (!match) return false;
      return true;
    }).filter(isValidStream);
  }
  return streams.filter((v) => !v.is_dir).filter(isValidStream);
}
const iv = atob("d0VpcGhUbiE=");
const key = atob("MTIzZDZjZWRmNjI2ZHk1NDIzM2FhMXc2");
const apiUrls = [
  atob("aHR0cHM6Ly9zaG93Ym94LnNoZWd1Lm5ldC9hcGkvYXBpX2NsaWVudC9pbmRleC8="),
  atob("aHR0cHM6Ly9tYnBhcGkuc2hlZ3UubmV0L2FwaS9hcGlfY2xpZW50L2luZGV4Lw==")
];
const appKey = atob("bW92aWVib3g=");
const appId = atob("Y29tLnRkby5zaG93Ym94");
const captionsDomains = [atob("bWJwaW1hZ2VzLmNodWF4aW4uY29t"), atob("aW1hZ2VzLnNoZWd1Lm5ldA==")];
const showboxBase = "https://www.showbox.media";
function encrypt(str) {
  return CryptoJS.TripleDES.encrypt(str, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv)
  }).toString();
}
function getVerify(str, str2, str3) {
  if (str) {
    return CryptoJS.MD5(CryptoJS.MD5(str2).toString() + str3 + str).toString();
  }
  return null;
}
const randomId = customAlphabet("1234567890abcdef");
const expiry = () => Math.floor(Date.now() / 1e3 + 60 * 60 * 12);
const sendRequest = async (ctx, data, altApi = false) => {
  const defaultData = {
    childmode: "0",
    app_version: "11.5",
    appid: appId,
    lang: "en",
    expired_date: `${expiry()}`,
    platform: "android",
    channel: "Website"
  };
  const encryptedData = encrypt(
    JSON.stringify({
      ...defaultData,
      ...data
    })
  );
  const appKeyHash = CryptoJS.MD5(appKey).toString();
  const verify = getVerify(encryptedData, appKey, key);
  const body = JSON.stringify({
    app_key: appKeyHash,
    verify,
    encrypt_data: encryptedData
  });
  const base64body = btoa(body);
  const formatted = new URLSearchParams();
  formatted.append("data", base64body);
  formatted.append("appid", "27");
  formatted.append("platform", "android");
  formatted.append("version", "129");
  formatted.append("medium", "Website");
  formatted.append("token", randomId(32));
  const requestUrl = altApi ? apiUrls[1] : apiUrls[0];
  const response = await ctx.proxiedFetcher(requestUrl, {
    method: "POST",
    headers: {
      Platform: "android",
      "Content-Type": "application/x-www-form-urlencoded",
      "User-Agent": "okhttp/3.2.0"
    },
    body: formatted
  });
  return JSON.parse(response);
};
async function getSubtitles(ctx, id, fid, type, episodeId, seasonId) {
  const module = type === "movie" ? "Movie_srt_list_v2" : "TV_srt_list_v2";
  const subtitleApiQuery = {
    fid,
    uid: "",
    module,
    mid: type === "movie" ? id : void 0,
    tid: type !== "movie" ? id : void 0,
    episode: episodeId == null ? void 0 : episodeId.toString(),
    season: seasonId == null ? void 0 : seasonId.toString()
  };
  const subResult = await sendRequest(ctx, subtitleApiQuery);
  const subtitleList = subResult.data.list;
  let output = [];
  subtitleList.forEach((sub) => {
    const subtitle = sub.subtitles.sort((a, b) => b.order - a.order)[0];
    if (!subtitle) return;
    const subtitleFilePath = subtitle.file_path.replace(captionsDomains[0], captionsDomains[1]).replace(/\s/g, "+").replace(/[()]/g, (c) => {
      return `%${c.charCodeAt(0).toString(16)}`;
    });
    const subtitleType = getCaptionTypeFromUrl(subtitleFilePath);
    if (!subtitleType) return;
    const validCode = isValidLanguageCode(subtitle.lang);
    if (!validCode) return;
    output.push({
      id: subtitleFilePath,
      language: subtitle.lang,
      hasCorsRestrictions: true,
      type: subtitleType,
      url: subtitleFilePath
    });
  });
  output = removeDuplicatedLanguages(output);
  return output;
}
function extractShareKey(url) {
  const parsedUrl = new URL(url);
  const shareKey = parsedUrl.pathname.split("/")[2];
  return shareKey;
}
const febboxHlsScraper = makeEmbed({
  id: "febbox-hls",
  name: "Febbox (HLS)",
  rank: 160,
  disabled: true,
  async scrape(ctx) {
    var _a;
    const { type, id, season, episode } = parseInputUrl(ctx.url);
    const sharelinkResult = await ctx.proxiedFetcher("/index/share_link", {
      baseUrl: showboxBase,
      query: {
        id,
        type: type === "movie" ? "1" : "2"
      }
    });
    if (!((_a = sharelinkResult == null ? void 0 : sharelinkResult.data) == null ? void 0 : _a.link)) throw new Error("No embed url found");
    ctx.progress(30);
    const shareKey = extractShareKey(sharelinkResult.data.link);
    const fileList = await getStreams(ctx, shareKey, type, season, episode);
    const firstStream = fileList[0];
    if (!firstStream) throw new Error("No playable mp4 stream found");
    ctx.progress(70);
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          flags: [],
          captions: await getSubtitles(ctx, id, firstStream.fid, type, season, episode),
          playlist: `https://www.febbox.com/hls/main/${firstStream.oss_fid}.m3u8`
        }
      ]
    };
  }
});
const allowedQualities = ["360", "480", "720", "1080", "4k"];
function mapToQuality(quality) {
  const q = quality.real_quality.replace("p", "").toLowerCase();
  if (!allowedQualities.includes(q)) return null;
  return {
    real_quality: q,
    path: quality.path,
    fid: quality.fid
  };
}
async function getStreamQualities(ctx, apiQuery) {
  var _a;
  const mediaRes = (await sendRequest(ctx, apiQuery)).data;
  const qualityMap = mediaRes.list.map((v) => mapToQuality(v)).filter((v) => !!v);
  const qualities = {};
  allowedQualities.forEach((quality) => {
    const foundQuality = qualityMap.find((q) => q.real_quality === quality && q.path);
    if (foundQuality) {
      qualities[quality] = {
        type: "mp4",
        url: foundQuality.path
      };
    }
  });
  return {
    qualities,
    fid: (_a = mediaRes.list[0]) == null ? void 0 : _a.fid
  };
}
const febboxMp4Scraper = makeEmbed({
  id: "febbox-mp4",
  name: "Febbox (MP4)",
  rank: 190,
  async scrape(ctx) {
    const { type, id, season, episode } = parseInputUrl(ctx.url);
    let apiQuery = null;
    if (type === "movie") {
      apiQuery = {
        uid: "",
        module: "Movie_downloadurl_v3",
        mid: id,
        oss: "1",
        group: ""
      };
    } else if (type === "show") {
      apiQuery = {
        uid: "",
        module: "TV_downloadurl_v3",
        tid: id,
        season,
        episode,
        oss: "1",
        group: ""
      };
    }
    if (!apiQuery) throw Error("Incorrect type");
    const { qualities, fid } = await getStreamQualities(ctx, apiQuery);
    if (fid === void 0) throw new Error("No streamable file found");
    ctx.progress(70);
    return {
      stream: [
        {
          id: "primary",
          captions: await getSubtitles(ctx, id, fid, type, episode, season),
          qualities,
          type: "file",
          flags: [flags.CORS_ALLOWED]
        }
      ]
    };
  }
});
const linkRegex$5 = /file: ?"(http.*?)"/;
const tracksRegex$2 = /\{file:\s"([^"]+)",\skind:\s"thumbnails"\}/g;
const filelionsScraper = makeEmbed({
  id: "filelions",
  name: "filelions",
  rank: 115,
  async scrape(ctx) {
    const mainPageRes = await ctx.proxiedFetcher.full(ctx.url, {
      headers: {
        referer: ctx.url
      }
    });
    const mainPage = mainPageRes.body;
    const mainPageUrl = new URL(mainPageRes.finalUrl);
    const streamUrl = mainPage.match(linkRegex$5) ?? [];
    const thumbnailTrack = tracksRegex$2.exec(mainPage);
    const playlist = streamUrl[1];
    if (!playlist) throw new Error("Stream url not found");
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          playlist,
          flags: [flags.IP_LOCKED, flags.CORS_ALLOWED],
          captions: [],
          ...thumbnailTrack ? {
            thumbnailTrack: {
              type: "vtt",
              url: mainPageUrl.origin + thumbnailTrack[1]
            }
          } : {}
        }
      ]
    };
  }
});
const mixdropBase = "https://mixdrop.ag";
const packedRegex$2 = /(eval\(function\(p,a,c,k,e,d\){.*{}\)\))/;
const linkRegex$4 = /MDCore\.wurl="(.*?)";/;
const mixdropScraper = makeEmbed({
  id: "mixdrop",
  name: "MixDrop",
  rank: 198,
  async scrape(ctx) {
    let embedUrl = ctx.url;
    if (ctx.url.includes("primewire")) embedUrl = (await ctx.fetcher.full(ctx.url)).finalUrl;
    const embedId = new URL(embedUrl).pathname.split("/")[2];
    const streamRes = await ctx.proxiedFetcher(`/e/${embedId}`, {
      baseUrl: mixdropBase
    });
    const packed = streamRes.match(packedRegex$2);
    if (!packed) {
      throw new Error("failed to find packed mixdrop JavaScript");
    }
    const unpacked = unpacker.unpack(packed[1]);
    const link = unpacked.match(linkRegex$4);
    if (!link) {
      throw new Error("failed to find packed mixdrop source link");
    }
    const url = link[1];
    return {
      stream: [
        {
          id: "primary",
          type: "file",
          flags: [flags.IP_LOCKED],
          captions: [],
          qualities: {
            unknown: {
              type: "mp4",
              url: url.startsWith("http") ? url : `https:${url}`,
              // URLs don't always start with the protocol
              headers: {
                // MixDrop requires this header on all streams
                Referer: mixdropBase
              }
            }
          }
        }
      ]
    };
  }
});
const mp4uploadScraper = makeEmbed({
  id: "mp4upload",
  name: "mp4upload",
  rank: 170,
  async scrape(ctx) {
    const embed2 = await ctx.proxiedFetcher(ctx.url);
    const playerSrcRegex = new RegExp('(?<=player\\.src\\()\\s*{\\s*type:\\s*"[^"]+",\\s*src:\\s*"([^"]+)"\\s*}\\s*(?=\\);)', "s");
    const playerSrc = embed2.match(playerSrcRegex) ?? [];
    const streamUrl = playerSrc[1];
    if (!streamUrl) throw new Error("Stream url not found in embed code");
    return {
      stream: [
        {
          id: "primary",
          type: "file",
          flags: [flags.CORS_ALLOWED],
          captions: [],
          qualities: {
            "1080": {
              type: "mp4",
              url: streamUrl
            }
          }
        }
      ]
    };
  }
});
const hunterRegex = /eval\(function\(h,u,n,t,e,r\).*?\("(.*?)",\d*?,"(.*?)",(\d*?),(\d*?),\d*?\)\)/;
const linkRegex$3 = /file:"(.*?)"/;
function decodeHunter(encoded, mask, charCodeOffset, delimiterOffset) {
  const delimiter = mask[delimiterOffset];
  const chunks = encoded.split(delimiter).filter((chunk) => chunk);
  const decoded = chunks.map((chunk) => {
    const charCode = chunk.split("").reduceRight((c, value, index) => {
      return c + mask.indexOf(value) * delimiterOffset ** (chunk.length - 1 - index);
    }, 0);
    return String.fromCharCode(charCode - charCodeOffset);
  }).join("");
  return decoded;
}
const streambucketScraper = makeEmbed({
  id: "streambucket",
  name: "StreamBucket",
  rank: 196,
  // TODO - Disabled until ctx.fetcher and ctx.proxiedFetcher don't trigger bot detection
  disabled: true,
  async scrape(ctx) {
    const response = await fetch(ctx.url);
    const html = await response.text();
    if (html.includes("captcha-checkbox")) {
      throw new Error("StreamBucket got captchaed");
    }
    let regexResult = html.match(hunterRegex);
    if (!regexResult) {
      throw new Error("Failed to find StreamBucket hunter JavaScript");
    }
    const encoded = regexResult[1];
    const mask = regexResult[2];
    const charCodeOffset = Number(regexResult[3]);
    const delimiterOffset = Number(regexResult[4]);
    if (Number.isNaN(charCodeOffset)) {
      throw new Error("StreamBucket hunter JavaScript charCodeOffset is not a valid number");
    }
    if (Number.isNaN(delimiterOffset)) {
      throw new Error("StreamBucket hunter JavaScript delimiterOffset is not a valid number");
    }
    const decoded = decodeHunter(encoded, mask, charCodeOffset, delimiterOffset);
    regexResult = decoded.match(linkRegex$3);
    if (!regexResult) {
      throw new Error("Failed to find StreamBucket HLS link");
    }
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          playlist: regexResult[1],
          flags: [flags.CORS_ALLOWED],
          captions: []
        }
      ]
    };
  }
});
var commonjsGlobal = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
function getDefaultExportFromCjs(x) {
  return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, "default") ? x["default"] : x;
}
function getAugmentedNamespace(n) {
  if (n.__esModule) return n;
  var f = n.default;
  if (typeof f == "function") {
    var a = function a2() {
      if (this instanceof a2) {
        return Reflect.construct(f, arguments, this.constructor);
      }
      return f.apply(this, arguments);
    };
    a.prototype = f.prototype;
  } else a = {};
  Object.defineProperty(a, "__esModule", { value: true });
  Object.keys(n).forEach(function(k) {
    var d = Object.getOwnPropertyDescriptor(n, k);
    Object.defineProperty(a, k, d.get ? d : {
      enumerable: true,
      get: function() {
        return n[k];
      }
    });
  });
  return a;
}
var encBase64 = { exports: {} };
function commonjsRequire(path) {
  throw new Error('Could not dynamically require "' + path + '". Please configure the dynamicRequireTargets or/and ignoreDynamicRequires option of @rollup/plugin-commonjs appropriately for this require call to work.');
}
var core = { exports: {} };
const __viteBrowserExternal = {};
const __viteBrowserExternal$1 = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: __viteBrowserExternal
}, Symbol.toStringTag, { value: "Module" }));
const require$$0 = /* @__PURE__ */ getAugmentedNamespace(__viteBrowserExternal$1);
var hasRequiredCore;
function requireCore() {
  if (hasRequiredCore) return core.exports;
  hasRequiredCore = 1;
  (function(module, exports) {
    (function(root, factory) {
      {
        module.exports = factory();
      }
    })(commonjsGlobal, function() {
      var CryptoJS2 = CryptoJS2 || function(Math2, undefined$1) {
        var crypto;
        if (typeof window !== "undefined" && window.crypto) {
          crypto = window.crypto;
        }
        if (typeof self !== "undefined" && self.crypto) {
          crypto = self.crypto;
        }
        if (typeof globalThis !== "undefined" && globalThis.crypto) {
          crypto = globalThis.crypto;
        }
        if (!crypto && typeof window !== "undefined" && window.msCrypto) {
          crypto = window.msCrypto;
        }
        if (!crypto && typeof commonjsGlobal !== "undefined" && commonjsGlobal.crypto) {
          crypto = commonjsGlobal.crypto;
        }
        if (!crypto && typeof commonjsRequire === "function") {
          try {
            crypto = require$$0;
          } catch (err) {
          }
        }
        var cryptoSecureRandomInt = function() {
          if (crypto) {
            if (typeof crypto.getRandomValues === "function") {
              try {
                return crypto.getRandomValues(new Uint32Array(1))[0];
              } catch (err) {
              }
            }
            if (typeof crypto.randomBytes === "function") {
              try {
                return crypto.randomBytes(4).readInt32LE();
              } catch (err) {
              }
            }
          }
          throw new Error("Native crypto module could not be used to get secure random number.");
        };
        var create = Object.create || /* @__PURE__ */ function() {
          function F() {
          }
          return function(obj) {
            var subtype;
            F.prototype = obj;
            subtype = new F();
            F.prototype = null;
            return subtype;
          };
        }();
        var C = {};
        var C_lib = C.lib = {};
        var Base = C_lib.Base = /* @__PURE__ */ function() {
          return {
            /**
             * Creates a new object that inherits from this object.
             *
             * @param {Object} overrides Properties to copy into the new object.
             *
             * @return {Object} The new object.
             *
             * @static
             *
             * @example
             *
             *     var MyType = CryptoJS.lib.Base.extend({
             *         field: 'value',
             *
             *         method: function () {
             *         }
             *     });
             */
            extend: function(overrides) {
              var subtype = create(this);
              if (overrides) {
                subtype.mixIn(overrides);
              }
              if (!subtype.hasOwnProperty("init") || this.init === subtype.init) {
                subtype.init = function() {
                  subtype.$super.init.apply(this, arguments);
                };
              }
              subtype.init.prototype = subtype;
              subtype.$super = this;
              return subtype;
            },
            /**
             * Extends this object and runs the init method.
             * Arguments to create() will be passed to init().
             *
             * @return {Object} The new object.
             *
             * @static
             *
             * @example
             *
             *     var instance = MyType.create();
             */
            create: function() {
              var instance = this.extend();
              instance.init.apply(instance, arguments);
              return instance;
            },
            /**
             * Initializes a newly created object.
             * Override this method to add some logic when your objects are created.
             *
             * @example
             *
             *     var MyType = CryptoJS.lib.Base.extend({
             *         init: function () {
             *             // ...
             *         }
             *     });
             */
            init: function() {
            },
            /**
             * Copies properties into this object.
             *
             * @param {Object} properties The properties to mix in.
             *
             * @example
             *
             *     MyType.mixIn({
             *         field: 'value'
             *     });
             */
            mixIn: function(properties) {
              for (var propertyName in properties) {
                if (properties.hasOwnProperty(propertyName)) {
                  this[propertyName] = properties[propertyName];
                }
              }
              if (properties.hasOwnProperty("toString")) {
                this.toString = properties.toString;
              }
            },
            /**
             * Creates a copy of this object.
             *
             * @return {Object} The clone.
             *
             * @example
             *
             *     var clone = instance.clone();
             */
            clone: function() {
              return this.init.prototype.extend(this);
            }
          };
        }();
        var WordArray = C_lib.WordArray = Base.extend({
          /**
           * Initializes a newly created word array.
           *
           * @param {Array} words (Optional) An array of 32-bit words.
           * @param {number} sigBytes (Optional) The number of significant bytes in the words.
           *
           * @example
           *
           *     var wordArray = CryptoJS.lib.WordArray.create();
           *     var wordArray = CryptoJS.lib.WordArray.create([0x00010203, 0x04050607]);
           *     var wordArray = CryptoJS.lib.WordArray.create([0x00010203, 0x04050607], 6);
           */
          init: function(words, sigBytes) {
            words = this.words = words || [];
            if (sigBytes != undefined$1) {
              this.sigBytes = sigBytes;
            } else {
              this.sigBytes = words.length * 4;
            }
          },
          /**
           * Converts this word array to a string.
           *
           * @param {Encoder} encoder (Optional) The encoding strategy to use. Default: CryptoJS.enc.Hex
           *
           * @return {string} The stringified word array.
           *
           * @example
           *
           *     var string = wordArray + '';
           *     var string = wordArray.toString();
           *     var string = wordArray.toString(CryptoJS.enc.Utf8);
           */
          toString: function(encoder) {
            return (encoder || Hex).stringify(this);
          },
          /**
           * Concatenates a word array to this word array.
           *
           * @param {WordArray} wordArray The word array to append.
           *
           * @return {WordArray} This word array.
           *
           * @example
           *
           *     wordArray1.concat(wordArray2);
           */
          concat: function(wordArray) {
            var thisWords = this.words;
            var thatWords = wordArray.words;
            var thisSigBytes = this.sigBytes;
            var thatSigBytes = wordArray.sigBytes;
            this.clamp();
            if (thisSigBytes % 4) {
              for (var i = 0; i < thatSigBytes; i++) {
                var thatByte = thatWords[i >>> 2] >>> 24 - i % 4 * 8 & 255;
                thisWords[thisSigBytes + i >>> 2] |= thatByte << 24 - (thisSigBytes + i) % 4 * 8;
              }
            } else {
              for (var j = 0; j < thatSigBytes; j += 4) {
                thisWords[thisSigBytes + j >>> 2] = thatWords[j >>> 2];
              }
            }
            this.sigBytes += thatSigBytes;
            return this;
          },
          /**
           * Removes insignificant bits.
           *
           * @example
           *
           *     wordArray.clamp();
           */
          clamp: function() {
            var words = this.words;
            var sigBytes = this.sigBytes;
            words[sigBytes >>> 2] &= 4294967295 << 32 - sigBytes % 4 * 8;
            words.length = Math2.ceil(sigBytes / 4);
          },
          /**
           * Creates a copy of this word array.
           *
           * @return {WordArray} The clone.
           *
           * @example
           *
           *     var clone = wordArray.clone();
           */
          clone: function() {
            var clone = Base.clone.call(this);
            clone.words = this.words.slice(0);
            return clone;
          },
          /**
           * Creates a word array filled with random bytes.
           *
           * @param {number} nBytes The number of random bytes to generate.
           *
           * @return {WordArray} The random word array.
           *
           * @static
           *
           * @example
           *
           *     var wordArray = CryptoJS.lib.WordArray.random(16);
           */
          random: function(nBytes) {
            var words = [];
            for (var i = 0; i < nBytes; i += 4) {
              words.push(cryptoSecureRandomInt());
            }
            return new WordArray.init(words, nBytes);
          }
        });
        var C_enc = C.enc = {};
        var Hex = C_enc.Hex = {
          /**
           * Converts a word array to a hex string.
           *
           * @param {WordArray} wordArray The word array.
           *
           * @return {string} The hex string.
           *
           * @static
           *
           * @example
           *
           *     var hexString = CryptoJS.enc.Hex.stringify(wordArray);
           */
          stringify: function(wordArray) {
            var words = wordArray.words;
            var sigBytes = wordArray.sigBytes;
            var hexChars = [];
            for (var i = 0; i < sigBytes; i++) {
              var bite = words[i >>> 2] >>> 24 - i % 4 * 8 & 255;
              hexChars.push((bite >>> 4).toString(16));
              hexChars.push((bite & 15).toString(16));
            }
            return hexChars.join("");
          },
          /**
           * Converts a hex string to a word array.
           *
           * @param {string} hexStr The hex string.
           *
           * @return {WordArray} The word array.
           *
           * @static
           *
           * @example
           *
           *     var wordArray = CryptoJS.enc.Hex.parse(hexString);
           */
          parse: function(hexStr) {
            var hexStrLength = hexStr.length;
            var words = [];
            for (var i = 0; i < hexStrLength; i += 2) {
              words[i >>> 3] |= parseInt(hexStr.substr(i, 2), 16) << 24 - i % 8 * 4;
            }
            return new WordArray.init(words, hexStrLength / 2);
          }
        };
        var Latin1 = C_enc.Latin1 = {
          /**
           * Converts a word array to a Latin1 string.
           *
           * @param {WordArray} wordArray The word array.
           *
           * @return {string} The Latin1 string.
           *
           * @static
           *
           * @example
           *
           *     var latin1String = CryptoJS.enc.Latin1.stringify(wordArray);
           */
          stringify: function(wordArray) {
            var words = wordArray.words;
            var sigBytes = wordArray.sigBytes;
            var latin1Chars = [];
            for (var i = 0; i < sigBytes; i++) {
              var bite = words[i >>> 2] >>> 24 - i % 4 * 8 & 255;
              latin1Chars.push(String.fromCharCode(bite));
            }
            return latin1Chars.join("");
          },
          /**
           * Converts a Latin1 string to a word array.
           *
           * @param {string} latin1Str The Latin1 string.
           *
           * @return {WordArray} The word array.
           *
           * @static
           *
           * @example
           *
           *     var wordArray = CryptoJS.enc.Latin1.parse(latin1String);
           */
          parse: function(latin1Str) {
            var latin1StrLength = latin1Str.length;
            var words = [];
            for (var i = 0; i < latin1StrLength; i++) {
              words[i >>> 2] |= (latin1Str.charCodeAt(i) & 255) << 24 - i % 4 * 8;
            }
            return new WordArray.init(words, latin1StrLength);
          }
        };
        var Utf82 = C_enc.Utf8 = {
          /**
           * Converts a word array to a UTF-8 string.
           *
           * @param {WordArray} wordArray The word array.
           *
           * @return {string} The UTF-8 string.
           *
           * @static
           *
           * @example
           *
           *     var utf8String = CryptoJS.enc.Utf8.stringify(wordArray);
           */
          stringify: function(wordArray) {
            try {
              return decodeURIComponent(escape(Latin1.stringify(wordArray)));
            } catch (e) {
              throw new Error("Malformed UTF-8 data");
            }
          },
          /**
           * Converts a UTF-8 string to a word array.
           *
           * @param {string} utf8Str The UTF-8 string.
           *
           * @return {WordArray} The word array.
           *
           * @static
           *
           * @example
           *
           *     var wordArray = CryptoJS.enc.Utf8.parse(utf8String);
           */
          parse: function(utf8Str) {
            return Latin1.parse(unescape(encodeURIComponent(utf8Str)));
          }
        };
        var BufferedBlockAlgorithm = C_lib.BufferedBlockAlgorithm = Base.extend({
          /**
           * Resets this block algorithm's data buffer to its initial state.
           *
           * @example
           *
           *     bufferedBlockAlgorithm.reset();
           */
          reset: function() {
            this._data = new WordArray.init();
            this._nDataBytes = 0;
          },
          /**
           * Adds new data to this block algorithm's buffer.
           *
           * @param {WordArray|string} data The data to append. Strings are converted to a WordArray using UTF-8.
           *
           * @example
           *
           *     bufferedBlockAlgorithm._append('data');
           *     bufferedBlockAlgorithm._append(wordArray);
           */
          _append: function(data) {
            if (typeof data == "string") {
              data = Utf82.parse(data);
            }
            this._data.concat(data);
            this._nDataBytes += data.sigBytes;
          },
          /**
           * Processes available data blocks.
           *
           * This method invokes _doProcessBlock(offset), which must be implemented by a concrete subtype.
           *
           * @param {boolean} doFlush Whether all blocks and partial blocks should be processed.
           *
           * @return {WordArray} The processed data.
           *
           * @example
           *
           *     var processedData = bufferedBlockAlgorithm._process();
           *     var processedData = bufferedBlockAlgorithm._process(!!'flush');
           */
          _process: function(doFlush) {
            var processedWords;
            var data = this._data;
            var dataWords = data.words;
            var dataSigBytes = data.sigBytes;
            var blockSize = this.blockSize;
            var blockSizeBytes = blockSize * 4;
            var nBlocksReady = dataSigBytes / blockSizeBytes;
            if (doFlush) {
              nBlocksReady = Math2.ceil(nBlocksReady);
            } else {
              nBlocksReady = Math2.max((nBlocksReady | 0) - this._minBufferSize, 0);
            }
            var nWordsReady = nBlocksReady * blockSize;
            var nBytesReady = Math2.min(nWordsReady * 4, dataSigBytes);
            if (nWordsReady) {
              for (var offset = 0; offset < nWordsReady; offset += blockSize) {
                this._doProcessBlock(dataWords, offset);
              }
              processedWords = dataWords.splice(0, nWordsReady);
              data.sigBytes -= nBytesReady;
            }
            return new WordArray.init(processedWords, nBytesReady);
          },
          /**
           * Creates a copy of this object.
           *
           * @return {Object} The clone.
           *
           * @example
           *
           *     var clone = bufferedBlockAlgorithm.clone();
           */
          clone: function() {
            var clone = Base.clone.call(this);
            clone._data = this._data.clone();
            return clone;
          },
          _minBufferSize: 0
        });
        C_lib.Hasher = BufferedBlockAlgorithm.extend({
          /**
           * Configuration options.
           */
          cfg: Base.extend(),
          /**
           * Initializes a newly created hasher.
           *
           * @param {Object} cfg (Optional) The configuration options to use for this hash computation.
           *
           * @example
           *
           *     var hasher = CryptoJS.algo.SHA256.create();
           */
          init: function(cfg) {
            this.cfg = this.cfg.extend(cfg);
            this.reset();
          },
          /**
           * Resets this hasher to its initial state.
           *
           * @example
           *
           *     hasher.reset();
           */
          reset: function() {
            BufferedBlockAlgorithm.reset.call(this);
            this._doReset();
          },
          /**
           * Updates this hasher with a message.
           *
           * @param {WordArray|string} messageUpdate The message to append.
           *
           * @return {Hasher} This hasher.
           *
           * @example
           *
           *     hasher.update('message');
           *     hasher.update(wordArray);
           */
          update: function(messageUpdate) {
            this._append(messageUpdate);
            this._process();
            return this;
          },
          /**
           * Finalizes the hash computation.
           * Note that the finalize operation is effectively a destructive, read-once operation.
           *
           * @param {WordArray|string} messageUpdate (Optional) A final message update.
           *
           * @return {WordArray} The hash.
           *
           * @example
           *
           *     var hash = hasher.finalize();
           *     var hash = hasher.finalize('message');
           *     var hash = hasher.finalize(wordArray);
           */
          finalize: function(messageUpdate) {
            if (messageUpdate) {
              this._append(messageUpdate);
            }
            var hash = this._doFinalize();
            return hash;
          },
          blockSize: 512 / 32,
          /**
           * Creates a shortcut function to a hasher's object interface.
           *
           * @param {Hasher} hasher The hasher to create a helper for.
           *
           * @return {Function} The shortcut function.
           *
           * @static
           *
           * @example
           *
           *     var SHA256 = CryptoJS.lib.Hasher._createHelper(CryptoJS.algo.SHA256);
           */
          _createHelper: function(hasher) {
            return function(message, cfg) {
              return new hasher.init(cfg).finalize(message);
            };
          },
          /**
           * Creates a shortcut function to the HMAC's object interface.
           *
           * @param {Hasher} hasher The hasher to use in this HMAC helper.
           *
           * @return {Function} The shortcut function.
           *
           * @static
           *
           * @example
           *
           *     var HmacSHA256 = CryptoJS.lib.Hasher._createHmacHelper(CryptoJS.algo.SHA256);
           */
          _createHmacHelper: function(hasher) {
            return function(message, key2) {
              return new C_algo.HMAC.init(hasher, key2).finalize(message);
            };
          }
        });
        var C_algo = C.algo = {};
        return C;
      }(Math);
      return CryptoJS2;
    });
  })(core);
  return core.exports;
}
(function(module, exports) {
  (function(root, factory) {
    {
      module.exports = factory(requireCore());
    }
  })(commonjsGlobal, function(CryptoJS2) {
    (function() {
      var C = CryptoJS2;
      var C_lib = C.lib;
      var WordArray = C_lib.WordArray;
      var C_enc = C.enc;
      C_enc.Base64 = {
        /**
         * Converts a word array to a Base64 string.
         *
         * @param {WordArray} wordArray The word array.
         *
         * @return {string} The Base64 string.
         *
         * @static
         *
         * @example
         *
         *     var base64String = CryptoJS.enc.Base64.stringify(wordArray);
         */
        stringify: function(wordArray) {
          var words = wordArray.words;
          var sigBytes = wordArray.sigBytes;
          var map = this._map;
          wordArray.clamp();
          var base64Chars = [];
          for (var i = 0; i < sigBytes; i += 3) {
            var byte1 = words[i >>> 2] >>> 24 - i % 4 * 8 & 255;
            var byte2 = words[i + 1 >>> 2] >>> 24 - (i + 1) % 4 * 8 & 255;
            var byte3 = words[i + 2 >>> 2] >>> 24 - (i + 2) % 4 * 8 & 255;
            var triplet = byte1 << 16 | byte2 << 8 | byte3;
            for (var j = 0; j < 4 && i + j * 0.75 < sigBytes; j++) {
              base64Chars.push(map.charAt(triplet >>> 6 * (3 - j) & 63));
            }
          }
          var paddingChar = map.charAt(64);
          if (paddingChar) {
            while (base64Chars.length % 4) {
              base64Chars.push(paddingChar);
            }
          }
          return base64Chars.join("");
        },
        /**
         * Converts a Base64 string to a word array.
         *
         * @param {string} base64Str The Base64 string.
         *
         * @return {WordArray} The word array.
         *
         * @static
         *
         * @example
         *
         *     var wordArray = CryptoJS.enc.Base64.parse(base64String);
         */
        parse: function(base64Str) {
          var base64StrLength = base64Str.length;
          var map = this._map;
          var reverseMap = this._reverseMap;
          if (!reverseMap) {
            reverseMap = this._reverseMap = [];
            for (var j = 0; j < map.length; j++) {
              reverseMap[map.charCodeAt(j)] = j;
            }
          }
          var paddingChar = map.charAt(64);
          if (paddingChar) {
            var paddingIndex = base64Str.indexOf(paddingChar);
            if (paddingIndex !== -1) {
              base64StrLength = paddingIndex;
            }
          }
          return parseLoop(base64Str, base64StrLength, reverseMap);
        },
        _map: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
      };
      function parseLoop(base64Str, base64StrLength, reverseMap) {
        var words = [];
        var nBytes = 0;
        for (var i = 0; i < base64StrLength; i++) {
          if (i % 4) {
            var bits1 = reverseMap[base64Str.charCodeAt(i - 1)] << i % 4 * 2;
            var bits2 = reverseMap[base64Str.charCodeAt(i)] >>> 6 - i % 4 * 2;
            var bitsCombined = bits1 | bits2;
            words[nBytes >>> 2] |= bitsCombined << 24 - nBytes % 4 * 8;
            nBytes++;
          }
        }
        return WordArray.create(words, nBytes);
      }
    })();
    return CryptoJS2.enc.Base64;
  });
})(encBase64);
var encBase64Exports = encBase64.exports;
const Base64 = /* @__PURE__ */ getDefaultExportFromCjs(encBase64Exports);
var encUtf8 = { exports: {} };
(function(module, exports) {
  (function(root, factory) {
    {
      module.exports = factory(requireCore());
    }
  })(commonjsGlobal, function(CryptoJS2) {
    return CryptoJS2.enc.Utf8;
  });
})(encUtf8);
var encUtf8Exports = encUtf8.exports;
const Utf8 = /* @__PURE__ */ getDefaultExportFromCjs(encUtf8Exports);
async function fetchCaptchaToken(ctx, domain, recaptchaKey) {
  const domainHash = Base64.stringify(Utf8.parse(domain)).replace(/=/g, ".");
  const recaptchaRender = await ctx.proxiedFetcher(`https://www.google.com/recaptcha/api.js`, {
    query: {
      render: recaptchaKey
    }
  });
  const vToken = recaptchaRender.substring(
    recaptchaRender.indexOf("/releases/") + 10,
    recaptchaRender.indexOf("/recaptcha__en.js")
  );
  const recaptchaAnchor = await ctx.proxiedFetcher(
    `https://www.google.com/recaptcha/api2/anchor?cb=1&hl=en&size=invisible&cb=flicklax`,
    {
      query: {
        k: recaptchaKey,
        co: domainHash,
        v: vToken
      }
    }
  );
  const cToken = load(recaptchaAnchor)("#recaptcha-token").attr("value");
  if (!cToken) throw new Error("Unable to find cToken");
  const tokenData = await ctx.proxiedFetcher(`https://www.google.com/recaptcha/api2/reload`, {
    query: {
      v: vToken,
      reason: "q",
      k: recaptchaKey,
      c: cToken,
      sa: "",
      co: domain
    },
    headers: { referer: "https://www.google.com/recaptcha/api2/" },
    method: "POST"
  });
  const token = tokenData.match('rresp","(.+?)"');
  return token ? token[1] : null;
}
const streamsbScraper = makeEmbed({
  id: "streamsb",
  name: "StreamSB",
  rank: 150,
  async scrape(ctx) {
    const streamsbUrl = ctx.url.replace(".html", "").replace("embed-", "").replace("e/", "").replace("d/", "");
    const parsedUrl = new URL(streamsbUrl);
    const base = await ctx.proxiedFetcher(`${parsedUrl.origin}/d${parsedUrl.pathname}`);
    ctx.progress(20);
    const pageDoc = load(base);
    const dlDetails = [];
    pageDoc("[onclick^=download_video]").each((i, el) => {
      const $el = pageDoc(el);
      const funcContents = $el.attr("onclick");
      const regExpFunc = /download_video\('(.+?)','(.+?)','(.+?)'\)/;
      const matchesFunc = regExpFunc.exec(funcContents ?? "");
      if (!matchesFunc) return;
      const quality = $el.find("span").text();
      const regExpQuality = /(.+?) \((.+?)\)/;
      const matchesQuality = regExpQuality.exec(quality ?? "");
      if (!matchesQuality) return;
      dlDetails.push({
        parameters: [matchesFunc[1], matchesFunc[2], matchesFunc[3]],
        quality: {
          label: matchesQuality[1].trim(),
          size: matchesQuality[2]
        }
      });
    });
    ctx.progress(40);
    let dls = await Promise.all(
      dlDetails.map(async (dl) => {
        const query = {
          op: "download_orig",
          id: dl.parameters[0],
          mode: dl.parameters[1],
          hash: dl.parameters[2]
        };
        const getDownload = await ctx.proxiedFetcher(`/dl`, {
          query,
          baseUrl: parsedUrl.origin
        });
        const downloadDoc = load(getDownload);
        const recaptchaKey = downloadDoc(".g-recaptcha").attr("data-sitekey");
        if (!recaptchaKey) throw new Error("Unable to get captcha key");
        const captchaToken = await fetchCaptchaToken(ctx, parsedUrl.origin, recaptchaKey);
        if (!captchaToken) throw new Error("Unable to get captcha token");
        const dlForm = new FormData();
        dlForm.append("op", "download_orig");
        dlForm.append("id", dl.parameters[0]);
        dlForm.append("mode", dl.parameters[1]);
        dlForm.append("hash", dl.parameters[2]);
        dlForm.append("g-recaptcha-response", captchaToken);
        const download = await ctx.proxiedFetcher(`/dl`, {
          method: "POST",
          baseUrl: parsedUrl.origin,
          body: dlForm,
          query
        });
        const dlLink = load(download)(".btn.btn-light.btn-lg").attr("href");
        return {
          quality: dl.quality.label,
          url: dlLink
        };
      })
    );
    dls = dls.filter((d) => !!d.url);
    ctx.progress(80);
    const qualities = dls.reduce(
      (a, v) => {
        a[v.quality] = {
          type: "mp4",
          url: v.url
        };
        return a;
      },
      {}
    );
    return {
      stream: [
        {
          id: "primary",
          type: "file",
          flags: [flags.CORS_ALLOWED],
          qualities,
          captions: []
        }
      ]
    };
  }
});
function hexToChar(hex) {
  return String.fromCharCode(parseInt(hex, 16));
}
function decrypt(data, key2) {
  var _a;
  const formatedData = ((_a = data.match(/../g)) == null ? void 0 : _a.map(hexToChar).join("")) || "";
  return formatedData.split("").map((char, i) => String.fromCharCode(char.charCodeAt(0) ^ key2.charCodeAt(i % key2.length))).join("");
}
const turbovidScraper = makeEmbed({
  id: "turbovid",
  name: "Turbovid",
  rank: 122,
  async scrape(ctx) {
    var _a, _b;
    const baseUrl2 = new URL(ctx.url).origin;
    const embedPage = await ctx.proxiedFetcher(ctx.url);
    ctx.progress(30);
    const apkey = (_a = embedPage.match(/const\s+apkey\s*=\s*"(.*?)";/)) == null ? void 0 : _a[1];
    const xxid = (_b = embedPage.match(/const\s+xxid\s*=\s*"(.*?)";/)) == null ? void 0 : _b[1];
    if (!apkey || !xxid) throw new Error("Failed to get required values");
    const juiceKey = JSON.parse(
      await ctx.proxiedFetcher("/api/cucked/juice_key", {
        baseUrl: baseUrl2,
        headers: {
          referer: ctx.url
        }
      })
    ).juice;
    if (!juiceKey) throw new Error("Failed to fetch the key");
    ctx.progress(60);
    const data = JSON.parse(
      await ctx.proxiedFetcher("/api/cucked/the_juice/", {
        baseUrl: baseUrl2,
        query: {
          [apkey]: xxid
        },
        headers: {
          referer: ctx.url
        }
      })
    ).data;
    if (!data) throw new Error("Failed to fetch required data");
    ctx.progress(90);
    const playlist = decrypt(data, juiceKey);
    return {
      stream: [
        {
          type: "hls",
          id: "primary",
          playlist,
          headers: {
            referer: baseUrl2
          },
          flags: [],
          captions: []
        }
      ]
    };
  }
});
const origin = "https://rabbitstream.net";
const referer$2 = "https://rabbitstream.net/";
const { AES: AES$1, enc } = CryptoJS;
function isJSON(json) {
  try {
    JSON.parse(json);
    return true;
  } catch {
    return false;
  }
}
function extractKey(script) {
  const startOfSwitch = script.lastIndexOf("switch");
  const endOfCases = script.indexOf("partKeyStartPosition");
  const switchBody = script.slice(startOfSwitch, endOfCases);
  const nums = [];
  const matches = switchBody.matchAll(/:[a-zA-Z0-9]+=([a-zA-Z0-9]+),[a-zA-Z0-9]+=([a-zA-Z0-9]+);/g);
  for (const match of matches) {
    const innerNumbers = [];
    for (const varMatch of [match[1], match[2]]) {
      const regex = new RegExp(`${varMatch}=0x([a-zA-Z0-9]+)`, "g");
      const varMatches = [...script.matchAll(regex)];
      const lastMatch = varMatches[varMatches.length - 1];
      if (!lastMatch) return null;
      const number = parseInt(lastMatch[1], 16);
      innerNumbers.push(number);
    }
    nums.push([innerNumbers[0], innerNumbers[1]]);
  }
  return nums;
}
const upcloudScraper = makeEmbed({
  id: "upcloud",
  name: "UpCloud",
  rank: 200,
  disabled: true,
  async scrape(ctx) {
    const parsedUrl = new URL(ctx.url.replace("embed-5", "embed-4"));
    const dataPath = parsedUrl.pathname.split("/");
    const dataId = dataPath[dataPath.length - 1];
    const streamRes = await ctx.proxiedFetcher(`${parsedUrl.origin}/ajax/embed-4/getSources?id=${dataId}`, {
      headers: {
        Referer: parsedUrl.origin,
        "X-Requested-With": "XMLHttpRequest"
      }
    });
    let sources = null;
    if (!isJSON(streamRes.sources)) {
      const scriptJs = await ctx.proxiedFetcher(`https://rabbitstream.net/js/player/prod/e4-player.min.js`, {
        query: {
          // browser side caching on this endpoint is quite extreme. Add version query paramter to circumvent any caching
          v: Date.now().toString()
        }
      });
      const decryptionKey = extractKey(scriptJs);
      if (!decryptionKey) throw new Error("Key extraction failed");
      let extractedKey = "";
      let strippedSources = streamRes.sources;
      let totalledOffset = 0;
      decryptionKey.forEach(([a, b]) => {
        const start = a + totalledOffset;
        const end = start + b;
        extractedKey += streamRes.sources.slice(start, end);
        strippedSources = strippedSources.replace(streamRes.sources.substring(start, end), "");
        totalledOffset += b;
      });
      const decryptedStream = AES$1.decrypt(strippedSources, extractedKey).toString(enc.Utf8);
      const parsedStream = JSON.parse(decryptedStream)[0];
      if (!parsedStream) throw new Error("No stream found");
      sources = parsedStream;
    }
    if (!sources) throw new Error("upcloud source not found");
    const captions = [];
    streamRes.tracks.forEach((track) => {
      if (track.kind !== "captions") return;
      const type = getCaptionTypeFromUrl(track.file);
      if (!type) return;
      const language = labelToLanguageCode(track.label.split(" ")[0]);
      if (!language) return;
      captions.push({
        id: track.file,
        language,
        hasCorsRestrictions: false,
        type,
        url: track.file
      });
    });
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          playlist: sources.file,
          flags: [flags.CORS_ALLOWED],
          captions,
          preferredHeaders: {
            Referer: referer$2,
            Origin: origin
          }
        }
      ]
    };
  }
});
const packedRegex$1 = /(eval\(function\(p,a,c,k,e,d\).*\)\)\))/;
const linkRegex$2 = /sources:\[{file:"(.*?)"/;
const upstreamScraper = makeEmbed({
  id: "upstream",
  name: "UpStream",
  rank: 199,
  async scrape(ctx) {
    const streamRes = await ctx.proxiedFetcher(ctx.url);
    const packed = streamRes.match(packedRegex$1);
    if (packed) {
      const unpacked = unpacker.unpack(packed[1]);
      const link = unpacked.match(linkRegex$2);
      if (link) {
        return {
          stream: [
            {
              id: "primary",
              type: "hls",
              playlist: link[1],
              flags: [flags.CORS_ALLOWED],
              captions: []
            }
          ]
        };
      }
    }
    throw new Error("upstream source not found");
  }
});
const vidsrcRCPBase = "https://vidsrc.stream";
const hlsURLRegex = /file:"(.*?)"/;
const setPassRegex = /var pass_path = "(.*set_pass\.php.*)";/;
function formatHlsB64(data) {
  const encodedB64 = data.replace(/\/@#@\/[^=/]+==/g, "");
  if (encodedB64.match(/\/@#@\/[^=/]+==/)) {
    return formatHlsB64(encodedB64);
  }
  return encodedB64;
}
const vidsrcembedScraper = makeEmbed({
  id: "vidsrcembed",
  // VidSrc is both a source and an embed host
  name: "VidSrc",
  rank: 197,
  async scrape(ctx) {
    var _a, _b, _c;
    const html = await ctx.proxiedFetcher(ctx.url, {
      headers: {
        referer: ctx.url
      }
    });
    let hlsMatch = (_b = (_a = html.match(hlsURLRegex)) == null ? void 0 : _a[1]) == null ? void 0 : _b.slice(2);
    if (!hlsMatch) throw new Error("Unable to find HLS playlist");
    hlsMatch = formatHlsB64(hlsMatch);
    const finalUrl = atob(hlsMatch);
    if (!finalUrl.includes(".m3u8")) throw new Error("Unable to find HLS playlist");
    let setPassLink = (_c = html.match(setPassRegex)) == null ? void 0 : _c[1];
    if (setPassLink) {
      if (setPassLink.startsWith("//")) {
        setPassLink = `https:${setPassLink}`;
      }
      await ctx.proxiedFetcher(setPassLink, {
        headers: {
          referer: ctx.url
        }
      });
    }
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          playlist: finalUrl,
          headers: {
            Referer: vidsrcRCPBase,
            Origin: vidsrcRCPBase
          },
          flags: [],
          captions: []
        }
      ]
    };
  }
});
const evalCodeRegex$2 = /eval\((.*)\)/g;
const fileRegex$1 = /file:"(.*?)"/g;
const tracksRegex$1 = /\{file:"([^"]+)",kind:"thumbnails"\}/g;
const vTubeScraper = makeEmbed({
  id: "vtube",
  name: "vTube",
  rank: 145,
  scrape: async (ctx) => {
    const mainPageRes = await ctx.proxiedFetcher.full(ctx.url, {
      headers: {
        referer: ctx.url
      }
    });
    const mainPage = mainPageRes.body;
    const html = load(mainPage);
    const evalCode = html("script").text().match(evalCodeRegex$2);
    if (!evalCode) throw new Error("Failed to find eval code");
    const unpacked = unpack(evalCode == null ? void 0 : evalCode.toString());
    const file = fileRegex$1.exec(unpacked);
    const thumbnailTrack = tracksRegex$1.exec(unpacked);
    if (!(file == null ? void 0 : file[1])) throw new Error("Failed to find file");
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          playlist: file[1],
          flags: [flags.CORS_ALLOWED],
          captions: [],
          ...thumbnailTrack ? {
            thumbnailTrack: {
              type: "vtt",
              url: new URL(mainPageRes.finalUrl).origin + thumbnailTrack[1]
            }
          } : {}
        }
      ]
    };
  }
});
const providers = [
  {
    id: "autoembed-english",
    rank: 10
  },
  {
    id: "autoembed-hindi",
    rank: 9
  },
  {
    id: "autoembed-tamil",
    rank: 8
  },
  {
    id: "autoembed-telugu",
    rank: 7
  },
  {
    id: "autoembed-bengali",
    rank: 6
  }
];
function embed(provider) {
  return makeEmbed({
    id: provider.id,
    name: provider.id.charAt(0).toUpperCase() + provider.id.slice(1),
    rank: provider.rank,
    async scrape(ctx) {
      return {
        stream: [
          {
            id: "primary",
            type: "hls",
            playlist: ctx.url,
            flags: [flags.CORS_ALLOWED],
            captions: []
          }
        ]
      };
    }
  });
}
const [
  autoembedEnglishScraper,
  autoembedHindiScraper,
  autoembedBengaliScraper,
  autoembedTamilScraper,
  autoembedTeluguScraper
] = providers.map(embed);
const evalCodeRegex$1 = /eval\((.*)\)/g;
const mp4Regex = /https?:\/\/.*\.mp4/;
const bflixScraper = makeEmbed({
  id: "bflix",
  name: "bFlix",
  rank: 113,
  scrape: async (ctx) => {
    const mainPage = await ctx.proxiedFetcher(ctx.url);
    const evalCode = mainPage.match(evalCodeRegex$1);
    if (!evalCode) throw new Error("Failed to find eval code");
    const unpacked = unpack(evalCode[0]);
    const file = unpacked.match(mp4Regex);
    if (!(file == null ? void 0 : file[0])) throw new Error("Failed to find file");
    return {
      stream: [
        {
          id: "primary",
          type: "file",
          flags: [],
          captions: [],
          qualities: {
            unknown: {
              type: "mp4",
              url: file[0]
            }
          },
          headers: {
            Referer: "https://bflix.gs/"
          }
        }
      ]
    };
  }
});
const referer$1 = "https://ridomovies.tv/";
const closeLoadScraper = makeEmbed({
  id: "closeload",
  name: "CloseLoad",
  rank: 106,
  async scrape(ctx) {
    var _a;
    const baseUrl2 = new URL(ctx.url).origin;
    const iframeRes = await ctx.proxiedFetcher(ctx.url, {
      headers: { referer: referer$1 }
    });
    const iframeRes$ = load(iframeRes);
    const captions = iframeRes$("track").map((_, el) => {
      const track = iframeRes$(el);
      const url2 = `${baseUrl2}${track.attr("src")}`;
      const label = track.attr("label") ?? "";
      const language = labelToLanguageCode(label);
      const captionType = getCaptionTypeFromUrl(url2);
      if (!language || !captionType) return null;
      return {
        id: url2,
        language,
        hasCorsRestrictions: true,
        type: captionType,
        url: url2
      };
    }).get().filter((x) => x !== null);
    const evalCode = iframeRes$("script").filter((_, el) => {
      var _a2;
      const script = iframeRes$(el);
      return (script.attr("type") === "text/javascript" && ((_a2 = script.html()) == null ? void 0 : _a2.includes("p,a,c,k,e,d"))) ?? false;
    }).html();
    if (!evalCode) throw new Error("Couldn't find eval code");
    const decoded = unpack(evalCode);
    const regexPattern = /var\s+(\w+)\s*=\s*"([^"]+)";/g;
    const base64EncodedUrl = (_a = regexPattern.exec(decoded)) == null ? void 0 : _a[2];
    if (!base64EncodedUrl) throw new NotFoundError("Unable to find source url");
    const url = atob(base64EncodedUrl);
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          playlist: url,
          captions,
          flags: [flags.IP_LOCKED],
          headers: {
            Referer: "https://closeload.top/",
            Origin: "https://closeload.top"
          }
        }
      ]
    };
  }
});
const evalCodeRegex = /eval\((.*)\)/g;
const fileRegex = /file:"(.*?)"/g;
const fileMoonScraper = makeEmbed({
  id: "filemoon",
  name: "Filemoon",
  rank: 300,
  scrape: async (ctx) => {
    const embedRes = await ctx.proxiedFetcher(ctx.url, {
      headers: {
        referer: ctx.url
      }
    });
    const embedHtml = load(embedRes);
    const evalCode = embedHtml("script").text().match(evalCodeRegex);
    if (!evalCode) throw new Error("Failed to find eval code");
    const unpacked = unpack(evalCode[0]);
    const file = fileRegex.exec(unpacked);
    if (!(file == null ? void 0 : file[1])) throw new Error("Failed to find file");
    const url = new URL(ctx.url);
    const subtitlesLink = url.searchParams.get("sub.info");
    const captions = [];
    if (subtitlesLink) {
      const captionsResult = await ctx.proxiedFetcher(subtitlesLink);
      for (const caption of captionsResult) {
        const language = labelToLanguageCode(caption.label);
        const captionType = getCaptionTypeFromUrl(caption.file);
        if (!language || !captionType) continue;
        captions.push({
          id: caption.file,
          url: caption.file,
          type: captionType,
          language,
          hasCorsRestrictions: false
        });
      }
    }
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          playlist: file[1],
          flags: [flags.IP_LOCKED],
          captions
        }
      ]
    };
  }
});
const fileMoonMp4Scraper = makeEmbed({
  id: "filemoon-mp4",
  name: "Filemoon MP4",
  rank: 400,
  scrape: async (ctx) => {
    const result = await fileMoonScraper.scrape(ctx);
    if (!result.stream) throw new NotFoundError("Failed to find result");
    if (result.stream[0].type !== "hls") throw new NotFoundError("Failed to find hls stream");
    const url = result.stream[0].playlist.replace(/\/hls2\//, "/download/").replace(/\.m3u8/, ".mp4");
    return {
      stream: [
        {
          id: "primary",
          type: "file",
          qualities: {
            unknown: {
              type: "mp4",
              url
            }
          },
          flags: [flags.IP_LOCKED],
          captions: result.stream[0].captions
        }
      ]
    };
  }
});
const hydraxScraper = makeEmbed({
  id: "hydrax",
  name: "Hydrax",
  rank: 250,
  async scrape(ctx) {
    const embed2 = await ctx.proxiedFetcher(ctx.url);
    const match = embed2.match(/PLAYER\(atob\("(.*?)"/);
    if (!(match == null ? void 0 : match[1])) throw new Error("No Data Found");
    ctx.progress(50);
    const qualityMatch = embed2.match(/({"pieceLength.+?})/);
    let qualityData = {};
    if (qualityMatch == null ? void 0 : qualityMatch[1]) qualityData = JSON.parse(qualityMatch[1]);
    const data = JSON.parse(atob(match[1]));
    if (!data.id || !data.domain) throw new Error("Required values missing");
    const domain = new URL((await ctx.proxiedFetcher.full(`https://${data.domain}`)).finalUrl).hostname;
    ctx.progress(100);
    return {
      stream: [
        {
          id: "primary",
          type: "file",
          qualities: {
            ...(qualityData == null ? void 0 : qualityData.fullHd) && {
              1080: {
                type: "mp4",
                url: `https://${domain}/whw${data.id}`
              }
            },
            ...(qualityData == null ? void 0 : qualityData.hd) && {
              720: {
                type: "mp4",
                url: `https://${domain}/www${data.id}`
              }
            },
            ...(qualityData == null ? void 0 : qualityData.mHd) && {
              480: {
                type: "mp4",
                url: `https://${domain}/${data.id}`
              }
            },
            360: {
              type: "mp4",
              url: `https://${domain}/${data.id}`
            }
          },
          headers: {
            Referer: ctx.url.replace(new URL(ctx.url).hostname, "abysscdn.com")
          },
          captions: [],
          flags: []
        }
      ]
    };
  }
});
const { AES, MD5 } = CryptoJS;
function mahoaData(input, key2) {
  const a = AES.encrypt(input, key2).toString();
  const b = a.replace("U2FsdGVkX1", "").replace(/\//g, "|a").replace(/\+/g, "|b").replace(/\\=/g, "|c").replace(/\|/g, "-z");
  return b;
}
function caesarShift(str, amount) {
  let output = "";
  for (let i = 0; i < str.length; i++) {
    let c = str[i];
    if (c.match(/[a-z]/i)) {
      const code = str.charCodeAt(i);
      if (code >= 65 && code <= 90) {
        c = String.fromCharCode((code - 65 + amount) % 26 + 65);
      } else if (code >= 97 && code <= 122) {
        c = String.fromCharCode((code - 97 + amount) % 26 + 97);
      }
    }
    output += c;
  }
  return output;
}
function stringToHex(tmp) {
  let str = "";
  for (let i = 0; i < tmp.length; i++) {
    str += tmp[i].charCodeAt(0).toString(16);
  }
  return str;
}
function generateResourceToken(idUser, idFile, domainRef) {
  const dataToken = stringToHex(
    caesarShift(mahoaData(`Win32|${idUser}|${idFile}|${domainRef}`, MD5("plhq@@@2022").toString()), 22)
  );
  const resourceToken = `${dataToken}|${MD5(`${dataToken}plhq@@@22`).toString()}`;
  return resourceToken;
}
const apiUrl = "https://api-post-iframe-rd.playm4u.xyz/api/playiframe";
const playm4uNMScraper = makeEmbed({
  id: "playm4u-nm",
  name: "PlayM4U",
  rank: 240,
  scrape: async (ctx) => {
    var _a, _b;
    const mainPage$ = load(await ctx.proxiedFetcher(ctx.url));
    const script = mainPage$(`script:contains("${apiUrl}")`).text();
    if (!script) throw new Error("Failed to get script");
    ctx.progress(50);
    const domainRef = "https://ww2.m4ufree.tv";
    const idFile = (_a = script.match(/var\s?idfile\s?=\s?"(.*)";/im)) == null ? void 0 : _a[1];
    const idUser = (_b = script.match(/var\s?iduser\s?=\s?"(.*)";/im)) == null ? void 0 : _b[1];
    if (!idFile || !idUser) throw new Error("Failed to get ids");
    const charecters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789=+";
    const apiRes = await ctx.proxiedFetcher(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        namekey: "playm4u03",
        token: Array.from({ length: 100 }, () => charecters.charAt(Math.floor(Math.random() * charecters.length))).join(
          ""
        ),
        referrer: domainRef,
        data: generateResourceToken(idUser, idFile, domainRef)
      })
    });
    if (!apiRes.data || apiRes.type !== "url-m3u8") throw new Error("Failed to get the stream");
    ctx.progress(100);
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          playlist: apiRes.data,
          captions: [],
          flags: []
        }
      ]
    };
  }
});
const referer = "https://ridomovies.tv/";
const ridooScraper = makeEmbed({
  id: "ridoo",
  name: "Ridoo",
  rank: 105,
  async scrape(ctx) {
    var _a;
    const res = await ctx.proxiedFetcher(ctx.url, {
      headers: {
        referer
      }
    });
    const regexPattern = /file:"([^"]+)"/g;
    const url = (_a = regexPattern.exec(res)) == null ? void 0 : _a[1];
    if (!url) throw new NotFoundError("Unable to find source url");
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          playlist: url,
          captions: [],
          flags: [flags.CORS_ALLOWED]
        }
      ]
    };
  }
});
function decode(str) {
  const b = ["U0ZML2RVN0IvRGx4", "MGNhL0JWb0kvTlM5", "Ym94LzJTSS9aU0Zj", "SGJ0L1dGakIvN0dX", "eE52L1QwOC96N0Yz"];
  let formatedB64 = str.slice(2);
  for (let i = 4; i > -1; i--) {
    formatedB64 = formatedB64.replace(`//${b[i]}`, "");
  }
  return atob(formatedB64);
}
const smashyStreamFScraper = makeEmbed({
  id: "smashystream-f",
  name: "SmashyStream (F)",
  rank: 71,
  async scrape(ctx) {
    var _a, _b;
    const res = await ctx.proxiedFetcher(ctx.url, {
      headers: {
        Referer: ctx.url
      }
    });
    if (!res.sourceUrls[0]) throw new NotFoundError("No watchable item found");
    const playlist = decode(res.sourceUrls[0]);
    if (!playlist.includes(".m3u8")) throw new Error("Failed to decode");
    const captions = ((_b = (_a = res.subtitles) == null ? void 0 : _a.match(/\[([^\]]+)\](https?:\/\/\S+?)(?=,\[|$)/g)) == null ? void 0 : _b.map((entry) => {
      const match = entry.match(/\[([^\]]+)\](https?:\/\/\S+?)(?=,\[|$)/);
      if (match) {
        const [, language, url] = match;
        if (language && url) {
          const languageCode = labelToLanguageCode(language.replace(/ - .*/, ""));
          const captionType = getCaptionTypeFromUrl(url);
          if (!languageCode || !captionType) return null;
          return {
            id: url,
            url: url.replace(",", ""),
            language: languageCode,
            type: captionType,
            hasCorsRestrictions: false
          };
        }
      }
      return null;
    }).filter((x) => x !== null)) ?? [];
    return {
      stream: [
        {
          id: "primary",
          playlist,
          type: "hls",
          flags: [flags.CORS_ALLOWED],
          captions
        }
      ]
    };
  }
});
const smashyStreamOScraper = makeEmbed({
  // the scraping logic for all smashystream embeds is the same
  // all the embeds can be added in the same way
  id: "smashystream-o",
  name: "SmashyStream (O)",
  rank: 70,
  async scrape(ctx) {
    const result = await smashyStreamFScraper.scrape(ctx);
    return {
      stream: result.stream
    };
  }
});
const streamtapeScraper = makeEmbed({
  id: "streamtape",
  name: "Streamtape",
  rank: 160,
  async scrape(ctx) {
    var _a;
    const embed2 = await ctx.proxiedFetcher(ctx.url);
    const match = embed2.match(/robotlink'\).innerHTML = (.*)'/);
    if (!match) throw new Error("No match found");
    const [fh, sh] = ((_a = match == null ? void 0 : match[1]) == null ? void 0 : _a.split("+ ('")) ?? [];
    if (!fh || !sh) throw new Error("No match found");
    const url = `https:${fh == null ? void 0 : fh.replace(/'/g, "").trim()}${sh == null ? void 0 : sh.substring(3).trim()}`;
    return {
      stream: [
        {
          id: "primary",
          type: "file",
          flags: [flags.CORS_ALLOWED, flags.IP_LOCKED],
          captions: [],
          qualities: {
            unknown: {
              type: "mp4",
              url
            }
          },
          headers: {
            Referer: "https://streamtape.com"
          }
        }
      ]
    };
  }
});
const packedRegex = /(eval\(function\(p,a,c,k,e,d\).*\)\)\))/;
const linkRegex$1 = /src:"(https:\/\/[^"]+)"/;
const streamvidScraper = makeEmbed({
  id: "streamvid",
  name: "Streamvid",
  rank: 215,
  async scrape(ctx) {
    const streamRes = await ctx.proxiedFetcher(ctx.url);
    const packed = streamRes.match(packedRegex);
    if (!packed) throw new Error("streamvid packed not found");
    const unpacked = unpacker.unpack(packed[1]);
    const link = unpacked.match(linkRegex$1);
    if (!link) throw new Error("streamvid link not found");
    return {
      stream: [
        {
          type: "hls",
          id: "primary",
          playlist: link[1],
          flags: [flags.CORS_ALLOWED],
          captions: []
        }
      ]
    };
  }
});
const vidCloudScraper = makeEmbed({
  id: "vidcloud",
  name: "VidCloud",
  rank: 201,
  disabled: true,
  async scrape(ctx) {
    const result = await upcloudScraper.scrape(ctx);
    return {
      stream: result.stream.map((s) => ({
        ...s,
        flags: []
      }))
    };
  }
});
const decodeData = (key2, data) => {
  const state = Array.from(Array(256).keys());
  let index1 = 0;
  for (let i = 0; i < 256; i += 1) {
    index1 = (index1 + state[i] + key2.charCodeAt(i % key2.length)) % 256;
    const temp = state[i];
    state[i] = state[index1];
    state[index1] = temp;
  }
  index1 = 0;
  let index2 = 0;
  let finalKey = "";
  for (let char = 0; char < data.length; char += 1) {
    index1 = (index1 + 1) % 256;
    index2 = (index2 + state[index1]) % 256;
    const temp = state[index1];
    state[index1] = state[index2];
    state[index2] = temp;
    if (typeof data[char] === "string") {
      finalKey += String.fromCharCode(data[char].charCodeAt(0) ^ state[(state[index1] + state[index2]) % 256]);
    } else if (typeof data[char] === "number") {
      finalKey += String.fromCharCode(data[char] ^ state[(state[index1] + state[index2]) % 256]);
    }
  }
  return finalKey;
};
const vidplayBase = "https://vidplay.online";
const getDecryptionKeys = async (ctx) => {
  var _a;
  const res = await ctx.proxiedFetcher("https://github.com/Ciarands/vidsrc-keys/blob/main/keys.json");
  const regex = /"rawLines":\s*\[([\s\S]*?)\]/;
  const rawLines = (_a = res.match(regex)) == null ? void 0 : _a[1];
  if (!rawLines) throw new Error("No keys found");
  const keys = JSON.parse(`${rawLines.substring(1).replace(/\\"/g, '"')}]`);
  return keys;
};
const getEncodedId = async (ctx) => {
  const url = new URL(ctx.url);
  const id = url.pathname.replace("/e/", "");
  const keyList = await getDecryptionKeys(ctx);
  const decodedId = decodeData(keyList[0], id);
  const encodedResult = decodeData(keyList[1], decodedId);
  const b64encoded = btoa(encodedResult);
  return b64encoded.replace("/", "_");
};
const getFuTokenKey = async (ctx) => {
  var _a;
  const id = await getEncodedId(ctx);
  const fuTokenRes = await ctx.proxiedFetcher("/futoken", {
    baseUrl: vidplayBase,
    headers: {
      referer: ctx.url
    }
  });
  const fuKey = (_a = fuTokenRes.match(/var\s+k\s*=\s*'([^']+)'/)) == null ? void 0 : _a[1];
  if (!fuKey) throw new Error("No fuKey found");
  const tokens = [];
  for (let i = 0; i < id.length; i += 1) {
    tokens.push(fuKey.charCodeAt(i % fuKey.length) + id.charCodeAt(i));
  }
  return `${fuKey},${tokens.join(",")}`;
};
const getFileUrl = async (ctx) => {
  const fuToken = await getFuTokenKey(ctx);
  return makeFullUrl(`/mediainfo/${fuToken}`, {
    baseUrl: vidplayBase,
    query: {
      ...Object.fromEntries(new URL(ctx.url).searchParams.entries()),
      autostart: "true"
    }
  });
};
const vidplayScraper = makeEmbed({
  id: "vidplay",
  name: "VidPlay",
  rank: 401,
  scrape: async (ctx) => {
    const fileUrl = await getFileUrl(ctx);
    const fileUrlRes = await ctx.proxiedFetcher(fileUrl, {
      headers: {
        referer: ctx.url
      }
    });
    if (typeof fileUrlRes.result === "number") throw new Error("File not found");
    const source = fileUrlRes.result.sources[0].file;
    const thumbnailSource = fileUrlRes.result.tracks.find((track) => track.kind === "thumbnails");
    let thumbnailTrack;
    if (thumbnailSource) {
      thumbnailTrack = {
        type: "vtt",
        url: thumbnailSource.file
      };
    }
    const url = new URL(ctx.url);
    const subtitlesLink = url.searchParams.get("sub.info");
    const captions = [];
    if (subtitlesLink) {
      const captionsResult = await ctx.proxiedFetcher(subtitlesLink);
      for (const caption of captionsResult) {
        const language = labelToLanguageCode(caption.label);
        const captionType = getCaptionTypeFromUrl(caption.file);
        if (!language || !captionType) continue;
        captions.push({
          id: caption.file,
          url: caption.file,
          type: captionType,
          language,
          hasCorsRestrictions: false
        });
      }
    }
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          playlist: source,
          flags: [flags.PROXY_BLOCKED],
          headers: {
            Referer: url.origin,
            Origin: url.origin
          },
          captions,
          thumbnailTrack
        }
      ]
    };
  }
});
const linkRegex = /'hls': ?'(http.*?)',/;
const tracksRegex = /previewThumbnails:\s{.*src:\["([^"]+)"]/;
const voeScraper = makeEmbed({
  id: "voe",
  name: "voe.sx",
  rank: 180,
  async scrape(ctx) {
    const embedRes = await ctx.proxiedFetcher.full(ctx.url);
    const embed2 = embedRes.body;
    const playerSrc = embed2.match(linkRegex) ?? [];
    const thumbnailTrack = embed2.match(tracksRegex);
    const streamUrl = playerSrc[1];
    if (!streamUrl) throw new Error("Stream url not found in embed code");
    return {
      stream: [
        {
          type: "hls",
          id: "primary",
          playlist: streamUrl,
          flags: [flags.CORS_ALLOWED, flags.IP_LOCKED],
          captions: [],
          headers: {
            Referer: "https://voe.sx"
          },
          ...thumbnailTrack ? {
            thumbnailTrack: {
              type: "vtt",
              url: new URL(embedRes.finalUrl).origin + thumbnailTrack[1]
            }
          } : {}
        }
      ]
    };
  }
});
async function getVideowlUrlStream(ctx, decryptedId) {
  var _a;
  const sharePage = await ctx.proxiedFetcher("https://cloud.mail.ru/public/uaRH/2PYWcJRpH");
  const regex = /"videowl_view":\{"count":"(\d+)","url":"([^"]+)"\}/g;
  const videowlUrl = (_a = regex.exec(sharePage)) == null ? void 0 : _a[2];
  if (!videowlUrl) throw new NotFoundError("Failed to get videoOwlUrl");
  return `${videowlUrl}/0p/${btoa(decryptedId)}.m3u8?${new URLSearchParams({
    double_encode: "1"
  })}`;
}
const warezcdnembedHlsScraper = makeEmbed({
  id: "warezcdnembedhls",
  // WarezCDN is both a source and an embed host
  name: "WarezCDN HLS",
  // method no longer works
  disabled: true,
  rank: 83,
  async scrape(ctx) {
    const decryptedId = await getDecryptedId(ctx);
    if (!decryptedId) throw new NotFoundError("can't get file id");
    const streamUrl = await getVideowlUrlStream(ctx, decryptedId);
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          flags: [flags.IP_LOCKED],
          captions: [],
          playlist: streamUrl
        }
      ]
    };
  }
});
const warezPlayerScraper = makeEmbed({
  id: "warezplayer",
  name: "warezPLAYER",
  rank: 85,
  async scrape(ctx) {
    const page = await ctx.proxiedFetcher.full(`/player.php`, {
      baseUrl: warezcdnPlayerBase,
      headers: {
        Referer: `${warezcdnApiBase}/getEmbed.php?${new URLSearchParams({
          id: ctx.url,
          sv: "warezcdn"
        })}`
      },
      query: {
        id: ctx.url
      }
    });
    const playerPageUrl = new URL(page.finalUrl);
    const hash = playerPageUrl.pathname.split("/")[2];
    const playerApiRes = await ctx.proxiedFetcher("/player/index.php", {
      baseUrl: playerPageUrl.origin,
      query: {
        data: hash,
        do: "getVideo"
      },
      method: "POST",
      body: new URLSearchParams({
        hash
      }),
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    });
    const sources = JSON.parse(playerApiRes);
    if (!sources.videoSource) throw new Error("Playlist not found");
    return {
      stream: [
        {
          id: "primary",
          type: "hls",
          flags: [],
          captions: [],
          playlist: sources.videoSource,
          headers: {
            // without this it returns "security error"
            Accept: "*/*"
          }
        }
      ]
    };
  }
});
function makeCookieHeader(cookies) {
  return Object.entries(cookies).map(([name, value]) => cookie.serialize(name, value)).join("; ");
}
function parseSetCookie(headerValue) {
  const splitHeaderValue = setCookieParser.splitCookiesString(headerValue);
  const parsedCookies = setCookieParser.parse(splitHeaderValue, {
    map: true
  });
  return parsedCookies;
}
const wootlyScraper = makeEmbed({
  id: "wootly",
  name: "wootly",
  rank: 172,
  async scrape(ctx) {
    var _a, _b;
    const baseUrl2 = "https://www.wootly.ch";
    const wootlyData = await ctx.proxiedFetcher.full(ctx.url, {
      method: "GET",
      readHeaders: ["Set-Cookie"]
    });
    const cookies = parseSetCookie(wootlyData.headers.get("Set-Cookie") || "");
    const wootssesCookie = cookies.wootsses.value;
    let $ = load(wootlyData.body);
    const iframeSrc = $("iframe").attr("src") ?? "";
    const woozCookieRequest = await ctx.proxiedFetcher.full(iframeSrc, {
      method: "GET",
      readHeaders: ["Set-Cookie"],
      headers: {
        cookie: makeCookieHeader({ wootsses: wootssesCookie })
      }
    });
    const woozCookies = parseSetCookie(woozCookieRequest.headers.get("Set-Cookie") || "");
    const woozCookie = woozCookies.wooz.value;
    const iframeData = await ctx.proxiedFetcher(iframeSrc, {
      method: "POST",
      body: new URLSearchParams({ qdf: "1" }),
      headers: {
        cookie: makeCookieHeader({ wooz: woozCookie }),
        Referer: iframeSrc
      }
    });
    $ = load(iframeData);
    const scriptText = $("script").html() ?? "";
    const tk = (_a = scriptText.match(/tk=([^;]+)/)) == null ? void 0 : _a[0].replace(/tk=|["\s]/g, "");
    const vd = (_b = scriptText.match(/vd=([^,]+)/)) == null ? void 0 : _b[0].replace(/vd=|["\s]/g, "");
    if (!tk || !vd) throw new Error("wootly source not found");
    const url = await ctx.proxiedFetcher(`/grabd`, {
      baseUrl: baseUrl2,
      query: { t: tk, id: vd },
      method: "GET",
      headers: {
        cookie: makeCookieHeader({ wooz: woozCookie, wootsses: wootssesCookie })
      }
    });
    if (!url) throw new Error("wootly source not found");
    return {
      stream: [
        {
          id: "primary",
          type: "file",
          flags: [flags.IP_LOCKED],
          captions: [],
          qualities: {
            unknown: {
              type: "mp4",
              url
            }
          }
        }
      ]
    };
  }
});
async function convertPlaylistsToDataUrls(fetcher, playlistUrl, headers2) {
  const playlistData = await fetcher(playlistUrl, { headers: headers2 });
  const playlist = parse(playlistData);
  if (playlist.isMasterPlaylist) {
    await Promise.all(
      playlist.variants.map(async (variant) => {
        const variantPlaylistData = await fetcher(variant.uri, { headers: headers2 });
        const variantPlaylist = parse(variantPlaylistData);
        variant.uri = `data:application/vnd.apple.mpegurl;base64,${btoa(stringify(variantPlaylist))}`;
      })
    );
  }
  return `data:application/vnd.apple.mpegurl;base64,${btoa(stringify(playlist))}`;
}
const baseUrl = "https://soaper.tv";
const universalScraper = async (ctx) => {
  const searchResult = await ctx.proxiedFetcher("/search.html", {
    baseUrl,
    query: {
      keyword: ctx.media.title
    }
  });
  const searchResult$ = load(searchResult);
  let showLink = searchResult$("a").filter((_, el) => searchResult$(el).text() === ctx.media.title).attr("href");
  if (!showLink) throw new NotFoundError("Content not found");
  if (ctx.media.type === "show") {
    const seasonNumber = ctx.media.season.number;
    const episodeNumber = ctx.media.episode.number;
    const showPage = await ctx.proxiedFetcher(showLink, { baseUrl });
    const showPage$ = load(showPage);
    const seasonBlock = showPage$("h4").filter((_, el) => showPage$(el).text().trim().split(":")[0].trim() === `Season${seasonNumber}`).parent();
    const episodes = seasonBlock.find("a").toArray();
    showLink = showPage$(
      episodes.find((el) => parseInt(showPage$(el).text().split(".")[0], 10) === episodeNumber)
    ).attr("href");
  }
  if (!showLink) throw new NotFoundError("Content not found");
  const contentPage = await ctx.proxiedFetcher(showLink, { baseUrl });
  const contentPage$ = load(contentPage);
  const pass = contentPage$("#hId").attr("value");
  if (!pass) throw new NotFoundError("Content not found");
  const formData = new URLSearchParams();
  formData.append("pass", pass);
  formData.append("e2", "0");
  formData.append("server", "0");
  const infoEndpoint = ctx.media.type === "show" ? "/home/index/getEInfoAjax" : "/home/index/getMInfoAjax";
  const streamRes = await ctx.proxiedFetcher(infoEndpoint, {
    baseUrl,
    method: "POST",
    body: formData,
    headers: {
      referer: `${baseUrl}${showLink}`
    }
  });
  const streamResJson = JSON.parse(streamRes);
  const captions = [];
  for (const sub of streamResJson.subs) {
    let language = "";
    if (sub.name.includes(".srt")) {
      language = labelToLanguageCode(sub.name.split(".srt")[0]);
    } else if (sub.name.includes(":")) {
      language = sub.name.split(":")[0];
    } else {
      language = sub.name;
    }
    if (!language) continue;
    captions.push({
      id: sub.path,
      url: sub.path,
      type: "srt",
      hasCorsRestrictions: false,
      language
    });
  }
  return {
    embeds: [],
    stream: [
      {
        id: "primary",
        playlist: await convertPlaylistsToDataUrls(ctx.proxiedFetcher, `${baseUrl}/${streamResJson.val}`),
        type: "hls",
        proxyDepth: 2,
        flags: [flags.CORS_ALLOWED],
        captions
      },
      ...streamResJson.val_bak ? [
        {
          id: "backup",
          playlist: await convertPlaylistsToDataUrls(ctx.proxiedFetcher, `${baseUrl}/${streamResJson.val_bak}`),
          type: "hls",
          flags: [flags.CORS_ALLOWED],
          proxyDepth: 2,
          captions
        }
      ] : []
    ]
  };
};
const soaperTvScraper = makeSourcerer({
  id: "soapertv",
  name: "Beta",
  rank: 126,
  flags: [flags.CORS_ALLOWED],
  scrapeMovie: universalScraper,
  scrapeShow: universalScraper
});
function gatherAllSources() {
  return [
    vidLinkData,
    whvxScraper,
    soaperTvScraper
  ];
}
function gatherAllEmbeds() {
  return [
    upcloudScraper,
    vidCloudScraper,
    mp4uploadScraper,
    streamsbScraper,
    upstreamScraper,
    febboxMp4Scraper,
    febboxHlsScraper,
    mixdropScraper,
    vidsrcembedScraper,
    streambucketScraper,
    smashyStreamFScraper,
    smashyStreamOScraper,
    ridooScraper,
    closeLoadScraper,
    fileMoonScraper,
    fileMoonMp4Scraper,
    deltaScraper,
    alphaScraper,
    vidplayScraper,
    wootlyScraper,
    doodScraper,
    streamvidScraper,
    voeScraper,
    streamtapeScraper,
    droploadScraper,
    filelionsScraper,
    vTubeScraper,
    warezcdnembedHlsScraper,
    warezcdnembedMp4Scraper,
    warezPlayerScraper,
    bflixScraper,
    playm4uNMScraper,
    hydraxScraper,
    autoembedEnglishScraper,
    autoembedHindiScraper,
    autoembedBengaliScraper,
    autoembedTamilScraper,
    autoembedTeluguScraper,
    turbovidScraper,
    novaScraper,
    astraScraper,
    orionScraper
  ];
}
function getBuiltinSources() {
  return gatherAllSources().filter((v) => !v.disabled);
}
function getBuiltinEmbeds() {
  return gatherAllEmbeds().filter((v) => !v.disabled);
}
function hasDuplicates(values) {
  return new Set(values).size !== values.length;
}
function getProviders(features, list) {
  const sources = list.sources.filter((v) => !(v == null ? void 0 : v.disabled));
  const embeds = list.embeds.filter((v) => !(v == null ? void 0 : v.disabled));
  const combined = [...sources, ...embeds];
  const anyDuplicateId = hasDuplicates(combined.map((v) => v.id));
  const anyDuplicateSourceRank = hasDuplicates(sources.map((v) => v.rank));
  const anyDuplicateEmbedRank = hasDuplicates(embeds.map((v) => v.rank));
  if (anyDuplicateId) throw new Error("Duplicate id found in sources/embeds");
  if (anyDuplicateSourceRank) throw new Error("Duplicate rank found in sources");
  if (anyDuplicateEmbedRank) throw new Error("Duplicate rank found in embeds");
  return {
    sources: sources.filter((s) => flagsAllowedInFeatures(features, s.flags)),
    embeds
  };
}
function makeProviders(ops) {
  const features = getTargetFeatures(
    ops.proxyStreams ? "any" : ops.target,
    ops.consistentIpForRequests ?? false,
    ops.proxyStreams
  );
  const list = getProviders(features, {
    embeds: getBuiltinEmbeds(),
    sources: getBuiltinSources()
  });
  return makeControls({
    embeds: list.embeds,
    sources: list.sources,
    features,
    fetcher: ops.fetcher,
    proxiedFetcher: ops.proxiedFetcher,
    proxyStreams: ops.proxyStreams
  });
}
function buildProviders() {
  let consistentIpForRequests = false;
  let target = null;
  let fetcher = null;
  let proxiedFetcher = null;
  const embeds = [];
  const sources = [];
  const builtinSources = getBuiltinSources();
  const builtinEmbeds = getBuiltinEmbeds();
  return {
    enableConsistentIpForRequests() {
      consistentIpForRequests = true;
      return this;
    },
    setFetcher(f) {
      fetcher = f;
      return this;
    },
    setProxiedFetcher(f) {
      proxiedFetcher = f;
      return this;
    },
    setTarget(t) {
      target = t;
      return this;
    },
    addSource(input) {
      if (typeof input !== "string") {
        sources.push(input);
        return this;
      }
      const matchingSource = builtinSources.find((v) => v.id === input);
      if (!matchingSource) throw new Error("Source not found");
      sources.push(matchingSource);
      return this;
    },
    addEmbed(input) {
      if (typeof input !== "string") {
        embeds.push(input);
        return this;
      }
      const matchingEmbed = builtinEmbeds.find((v) => v.id === input);
      if (!matchingEmbed) throw new Error("Embed not found");
      embeds.push(matchingEmbed);
      return this;
    },
    addBuiltinProviders() {
      sources.push(...builtinSources);
      embeds.push(...builtinEmbeds);
      return this;
    },
    build() {
      if (!target) throw new Error("Target not set");
      if (!fetcher) throw new Error("Fetcher not set");
      const features = getTargetFeatures(target, consistentIpForRequests);
      const list = getProviders(features, {
        embeds,
        sources
      });
      return makeControls({
        fetcher,
        proxiedFetcher: proxiedFetcher ?? void 0,
        embeds: list.embeds,
        sources: list.sources,
        features
      });
    }
  };
}
const isReactNative = () => {
  try {
    require("react-native");
    return true;
  } catch (e) {
    return false;
  }
};
function serializeBody(body) {
  if (body === void 0 || typeof body === "string" || body instanceof URLSearchParams || body instanceof FormData) {
    if (body instanceof URLSearchParams && isReactNative()) {
      return {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: body.toString()
      };
    }
    return {
      headers: {},
      body
    };
  }
  return {
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
}
function getHeaders(list, res) {
  const output = new Headers();
  list.forEach((header) => {
    var _a;
    const realHeader = header.toLowerCase();
    const realValue = res.headers.get(realHeader);
    const extraValue = (_a = res.extraHeaders) == null ? void 0 : _a.get(realHeader);
    const value = extraValue ?? realValue;
    if (!value) return;
    output.set(realHeader, value);
  });
  return output;
}
function makeStandardFetcher(f) {
  const normalFetch = async (url, ops) => {
    var _a;
    const fullUrl = makeFullUrl(url, ops);
    const seralizedBody = serializeBody(ops.body);
    const res = await f(fullUrl, {
      method: ops.method,
      headers: {
        ...seralizedBody.headers,
        ...ops.headers
      },
      body: seralizedBody.body,
      credentials: ops.credentials
    });
    let body;
    const isJson = (_a = res.headers.get("content-type")) == null ? void 0 : _a.includes("application/json");
    if (isJson) body = await res.json();
    else body = await res.text();
    return {
      body,
      finalUrl: res.extraUrl ?? res.url,
      headers: getHeaders(ops.readHeaders, res),
      statusCode: res.status
    };
  };
  return normalFetch;
}
const headerMap = {
  cookie: "X-Cookie",
  referer: "X-Referer",
  origin: "X-Origin",
  "user-agent": "X-User-Agent",
  "x-real-ip": "X-X-Real-Ip"
};
const responseHeaderMap = {
  "x-set-cookie": "Set-Cookie"
};
function makeSimpleProxyFetcher(proxyUrl, f) {
  const proxiedFetch = async (url, ops) => {
    const fetcher = makeStandardFetcher(async (a, b) => {
      const res = await f(a, b);
      res.extraHeaders = new Headers();
      Object.entries(responseHeaderMap).forEach((entry) => {
        var _a;
        const value = res.headers.get(entry[0]);
        if (!value) return;
        (_a = res.extraHeaders) == null ? void 0 : _a.set(entry[1].toLowerCase(), value);
      });
      res.extraUrl = res.headers.get("X-Final-Destination") ?? res.url;
      return res;
    });
    const fullUrl = makeFullUrl(url, ops);
    const headerEntries = Object.entries(ops.headers).map((entry) => {
      const key2 = entry[0].toLowerCase();
      if (headerMap[key2]) return [headerMap[key2], entry[1]];
      return entry;
    });
    return fetcher(proxyUrl, {
      ...ops,
      query: {
        destination: fullUrl
      },
      headers: Object.fromEntries(headerEntries),
      baseUrl: void 0
    });
  };
  return proxiedFetch;
}
export {
  NotFoundError,
  buildProviders,
  flags,
  getBuiltinEmbeds,
  getBuiltinSources,
  makeProviders,
  makeSimpleProxyFetcher,
  makeStandardFetcher,
  targets
};
